import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-number-attendees-print-certificate',
  templateUrl: './report-number-attendees-print-certificate.component.html',
  styleUrls: ['./report-number-attendees-print-certificate.component.css']
})
export class ReportNumberAttendeesPrintCertificateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
