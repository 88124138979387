import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ExecutiveSharedDataService {

    private dataSource = new BehaviorSubject<any>('');
    getDataExecutiveLogin = this.dataSource.asObservable();

    constructor() { }

    changeDataExecutiveLogin(val: any) {
        this.dataSource.next(val)
    }

}