import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { GradientConfig } from '../../../../../app-config';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NavigationItem } from '../../navigation/navigation';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthenInterface } from 'src/app/core/interfaces/auth-interface/authen-interface';
import { Role } from 'src/app/core/models/role';
import {
  SnotifyPosition,
  SnotifyService,
  SnotifyToastConfig,
} from 'ng-snotify';
import { Socket } from 'ngx-socket-io';
import { TeaCherSharedDataService } from 'src/app/core/common/TeaCherSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { LanguageService } from 'src/app/core/services/language-service/language.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  responseDataAdminInterface: any;
  localRole: any;

  //noti
  newTop = true;
  blockMax = 6;
  dockMax = 8;
  bodyMaxLength = 80;
  titleMaxLength = 15;
  backdrop = -1;
  position: SnotifyPosition = SnotifyPosition.rightTop;
  timeout = 5000;
  progressBar = true;
  closeClick = true;
  pauseHover = true;
  title = 'Snotify title!';
  body = 'Lorem ipsum dolor sit amet!';
  style = 'material';
  dataNoti: any = [];
  baseURL = Constants.REST_API_MAIN;
  flagvalue;
  countryName;
  defaultFlag: string;
  langStoreValue: string;

  timedOut = false;
  lastPing?: Date = null;
  dataLanguage: string;

  private socket: any;
  constructor(
    private teaCherSharedDataService: TeaCherSharedDataService,
    private socket_: Socket,
    private snotifyService: SnotifyService,
    private router: Router,
    private adminSharedDataService: AdminSharedDataService,
    private authService: AuthService,
    public languageService: LanguageService,
    private idle: Idle,
    private keepalive: Keepalive,
  ) {
    this.idleLoad();

    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;
  }
  listLang = [
    {
      text: 'English',
      flag: 'assets/images/flags/united-states.png',
      lang: 'en',
    },
    { text: 'Thai', flag: 'assets/images/flags/thailand.png', lang: 'th' },
  ];

  ngOnInit() {
    this.getLanguage();
    var local = localStorage.getItem('currentUserLogin');
    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (local != null) {
      var dataLocal = JSON.parse(local);
      this.localRole = dataLocal;
    }
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/united-states.png';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.loadSocket();
    this.loadDataNotification();
    this.defaultFlag = 'assets/images/flags/united-states.png';
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    localStorage.setItem('lang', lang);
    this.languageService.setLanguage(lang);
    // this.sharedServiceService.updateCompcompHeaderValLanguage(lang);
  }
  getLanguage() {
    this.dataLanguage = localStorage.getItem('lang');
  }

  onClickNoni() {
    this.loadDataNotification();
  }

  loadDataNotification() {
    this.getLanguage();
    var dataNotiPush = [];
    this.dataNoti = [];
    var haveData: boolean = false;
    this.teaCherSharedDataService.getDataCheckSubjectiveExam.subscribe(
      (message) => {
        if (message != null && message != '' && !haveData) {
          haveData = true;
          message.forEach((element) => {
            // console.log("element ", element);
            //.dataStudent
            dataNotiPush.push({
              img: element.dataPostTest.dataStudent.image,
              name: element.dataPostTest.dataStudent.fullNameTh,
              date: element.dataPostTest.postTestDate,
              content: this.dataLanguage == 'th' ? `ได้ส่งข้อสอบอัตนัย วิชา${element.dataSubject.subjectNameTh}` : `Submitted the subjective exam Subject ${element.dataSubject.subjectNameTh}`,
            });
          });
          // console.log(dataNotiPush);
          this.dataNoti = dataNotiPush;
        }
      }
    );

    this.teaCherSharedDataService.getDataTransferByStatusPayPending.subscribe(
      (message) => {
        if (message != null && message != '' && !haveData) {
          haveData = true;
          message.forEach((element) => {
            // console.log('element--22 ', element);
            if (element.dataBuyCourse[0] != undefined) {
              dataNotiPush.push({
                img: element.dataBuyCourse[0].dataStudent.image,
                name: element.dataBuyCourse[0].dataStudent.fullNameTh,
                date: element.dateTransfer,
                content: this.dataLanguage == 'th' ? `ได้สั่งซื้อหลักสูตรจำนวน ${element.dataBuyCourse.length} หลักสูตร` : `Have ordered a number of courses ${element.dataBuyCourse.length} course`,
              });
            }
          });
          // console.log(dataNotiPush);
          this.dataNoti = dataNotiPush;
        }
      }
    );
  }

  onClickOpenPage() {
    if (this.localRole.role == 'Admin') {
      this.router.navigate([`/admin/check-transfer`]);
    } else {
      this.router.navigate([`/teacher/teacherCheckExam`]);
    }
  }

  loadSocket() {
    this.socket_.on('connect', (data: any) => {
      console.log('socket connected');
    });
    this.socket_.on('actionElearningChannel', (data: any) => {
      if (
        this.localRole.role == 'Admin' && data.action == 'buyCourse' ||
        (this.localRole.role == 'SuperAdmin' && data.action == 'buyCourse')
      ) {
        this.onOpenAlertNoti(data.title, data.name, data.id, data.action);
      }

      if (
        this.localRole.role == 'Admin' && data.action == 'register' ||
        (this.localRole.role == 'SuperAdmin' && data.action == 'register')
      ) {
        this.onOpenAlertNotiRegister(data.title, data.name, data.id, data.action);
      }

      if (this.localRole.role == 'Teacher' && data.action == 'checkExam') {
        this.onOpenAlertNoti(data.title, data.name, data.id, data.action);
      }
    });
  }

  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
        // @ts-ignore
        filterDuplicates: this.filterDuplicates,
      },
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover,
    };
  }
  // <i class="fas fa-book text-c-green f-30"></i>
  // <i class="fas fa-shopping-cart text-c-green f-30"></i>
  onOpenAlertNoti(title: string, name: string, id: string, action: string) {
    this.playAudioAlert();

    var icon = `<i class="fas fa-shopping-cart text-c-green f-30"></i>`;
    var description = `<h6 class="m-b-0 text-c-green">คุณ ${name} ได้ส่งคำตอบ</h6>`;
    if (action == 'buyCourse') {
      icon = `<i class="fas fa-book text-c-red f-30"></i>`;
      description = `<h6 class="m-b-0 text-c-red">คุณ ${name} ได้ทำการสั้งซื้อหลักสูตร</h6>`;
    }
    const html = `<div class="row">
    <div class="col-auto">
      ${icon}
    </div>
    <div class="col p-l-0">
      <h6 class="m-b-5">${title}</h6>
      ${description}
    </div>
  </div>`;
    this.snotifyService.html(html, this.getConfig());
  }

  onOpenAlertNotiRegister(title: string, name: string, id: string, action: string) {
    this.playAudioAlert();
    var icon = `<i class="fas fa-user text-c-blue f-30"></i>`;
    var description = `<h6 class="m-b-0 text-c-blue">คุณ ${name} ได้ทำการสมัครสมาชิกใหม่</h6>`;
    const html = `<a href="/admin/comfirmation"><div class="row">
    <div class="col-auto">
      ${icon}
    </div>
    <div class="col p-l-0">
      <h6 class="m-b-5">${title}</h6>
      ${description}
    </div>
  </div></a>`;
    this.snotifyService.html(html, this.getConfig());
  }


  logout() {
    this.getLanguage();
    Swal.fire({
      // title: "กดปุ่มยืนยันเพื่อออกจากระบบ",
      text: this.dataLanguage == 'th' ? 'กดปุ่มยืนยันเพื่อออกจากระบบ' : 'Press the confirm button to log out.',
      imageUrl: 'assets/images/logout.gif',
      imageHeight: 150,
      confirmButtonColor: '#4099FF',
      showCancelButton: true,
      confirmButtonText: this.dataLanguage == 'th' ? 'ยืนยัน' : 'Confirm',
      cancelButtonColor: '#bdc3c7',
      cancelButtonText: this.dataLanguage == 'th' ? 'ยกเลิก' : 'Cancel',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('currentUserLogin');
        localStorage.removeItem("select_portal");
        this.router
          .navigate([`/auth/signin`], { replaceUrl: true })
          .then(() => {
            window.location.reload();
          });
      } else {
        console.log('error : ');
      }
    });
  }

  playAudioAlert() {
    let audio = new Audio(
      'assets/sound/mixkit-software-interface-back-2575.wav'
    );
    audio.load();
    audio.play();
  }

  idleLoad() {
    var sum = 3600 * 3;
    this.idle.setIdle(sum);
    this.idle.setTimeout(3);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logOutNow();
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.resetIdle();
  }

  resetIdle() {
    this.idle.watch();
    this.timedOut = false;
  }

  logOutNow() {
    localStorage.removeItem('currentUserLogin');
    localStorage.removeItem("select_portal");
    this.router.navigate([`/auth/signin`], { replaceUrl: true }).then(() => {
      window.location.reload();
    });
  }
}
