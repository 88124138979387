import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { ViewSummaryComponent } from './view-summary/view-summary.component';

@Component({
  selector: 'app-report-satisfaction',
  templateUrl: './report-satisfaction.component.html',
  styleUrls: ['./report-satisfaction.component.scss']
})
export class ReportSatisfactionComponent implements OnInit {
  @ViewChild(ViewSummaryComponent) childViewSummary: ViewSummaryComponent;
  baseURL = Constants.REST_API_MAIN;
  userData: any;
  adminId: string = '';
  responseSubject: any;
  dataSatisfactionQuestionnaire: any;
  datafindQuestion: any;
  dataLoading: boolean = true;
  constructor(
    private adminService: AdminService,
  ) { }

  ngOnInit() {
    this.loadAllSubject();
    this.findSatisfindQuestion();
  }

  loadAllSubject() {
    this.adminService.GetAllSubject().then((response) => {
      this.responseSubject = response.dataSubject;
    });
  }

  findSatisfindQuestion() {
    this.adminService.FindSatisfindQuestion().then((res) => {
      this.datafindQuestion = res.data;
      this.loadDataSatisfactionQuestionnaire();
    });
  }

  async loadDataSatisfactionQuestionnaire() {
    this.dataLoading = true;
    var dataGroupCouse: any = [];
    var array = [];
    await this.adminService.FindSatisfactionQuestionnaire().then(response => {
      if(response.status){ 
        for (let index = 0; index < response.data.length; index++) {
          array.push(response.data[index].score[0].score5 +
            response.data[index].score[0].score4 +
            response.data[index].score[0].score3 +
            response.data[index].score[0].score2 +
            response.data[index].score[0].score1 
            )
            if (response.data.length != 0) {
              var idSub = this.responseSubject.find(x => x.subjectId == response.data[index].subjectID);
              dataGroupCouse.push({'subjectData':idSub,'surveyData':response.data[index].score,'respondents':array[index]});
            }
        }
        this.dataSatisfactionQuestionnaire = dataGroupCouse;
        // setTimeout(() => {
          this.dataLoading = false;
        // }, 1000); 
      }else{
        // setTimeout(() => {
          this.dataLoading = false;
        // }, 1000);  
      }
      setTimeout(() => {
        this.dataSatisfactionQuestionnaire = dataGroupCouse;
      }, 500);
    }, error => {
      console.log("error : ", error);
    });
  }

  openViewSummary(subjectId) {
    this.childViewSummary.openViewSummary(subjectId,this.dataSatisfactionQuestionnaire);
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}
