<app-ui-modal #viewFormSummary [hideFooter]="true" [dialogClass]="'modal-xl'">
  <div class="app-modal-header">
    <h5 class="modal-title"><i class="feather icon-message-square mr-2"></i>{{'EXECUTIVE.AMOUNT-OF-PEOPLE-THAT-ANSWER-THE-SURVEY' | translate}}
      {{'EXECUTIVE.CLASSIFICATION-NAME' | translate}} {{subjectName}} {{'EXECUTIVE.TOTAL' | translate}} {{counting}} {{'EXECUTIVE.PERSON' | translate}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal"
      aria-label="Close"
      (click)="viewFormSummary.hide()">
      <span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-card [hidHeader]="true" cardClass="user-profile-list">
      <div class="dt-responsive table-responsive">
        <table dataable class="table table-striped row-borderr"
          *ngIf="datafindQuestion.length" [dtOptions]="dtOptions">
          <thead>
            <tr>
              <th>{{'EXECUTIVE.QUESTION' | translate}}</th>
              <th>{{'EXECUTIVE.THE-MOST' | translate}}</th>
              <th>{{'EXECUTIVE.MOST' | translate}}</th>
              <th>{{'EXECUTIVE.MODERATE' | translate}}</th>
              <th>{{'EXECUTIVE.LESS' | translate}}</th>
              <th>{{'EXECUTIVE.LEAST' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datafindQ of questionData;let i = index">
              <td style="overflow-wrap: break-word;">{{i + 1}}. {{datafindQ.question_name}}</td>
              <td style="text-align: center;">{{datafindQ.score5}}</td>
              <td style="text-align: center;">{{datafindQ.score4}}</td>
              <td style="text-align: center;">{{datafindQ.score3}}</td>
              <td style="text-align: center;">{{datafindQ.score2}}</td>
              <td style="text-align: center;">{{datafindQ.score1}}</td>
              <!-- <td>
                <span class="badge badge-light-danger" *ngIf="datafindQ.showType == ''">ลบ</span>
                <span class="badge badge-light-secondary" *ngIf="datafindQ.showType == 'off'">ปิด</span>
                <span class="badge badge-light-success" *ngIf="datafindQ.showType == 'on'">เปิด</span>
                <div class="overlay-edit">
                  <div class="form-group" *ngIf="datafindQ.showType != ''">
                    <div class="switch switch-danger d-inline m-r-10">
                      <input type="checkbox" id="switch-d-1-{{i}}" [checked]="datafindQ.showType == 'on' ? true : false"
                        (change)="checkedOnOff($event,datafindQ.satisfindQuestionId)">
                      <label for="switch-d-1-{{i}}" class="cr"></label>
                    </div>
                  </div>
                  <button type="button" class="btn btn-icon btn-danger" *ngIf="datafindQ.showType == ''"
                    (click)="remove(datafindQ.satisfindQuestionId,i)"><i class="feather icon-trash-2"></i></button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>

</app-ui-modal>