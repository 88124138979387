<div class="row btn-page">
  <div class="col-sm-12">
    <app-card cardTitle="Toast Live Customization">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="title">Title</label>
            <input [(ngModel)]="title" type="text" id="title" class="form-control" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="body">Body</label>
            <textarea [(ngModel)]="body" id="body" rows="2" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="titlemaxlen">Title max-length</label>
            <input [(ngModel)]="titleMaxLength" type="text" id="titlemaxlen" class="form-control" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="bodymaxlen">Body max-length</label>
            <input [(ngModel)]="bodyMaxLength" type="text" id="bodymaxlen" class="form-control" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="timeout">Timeout</label>
            <input [(ngModel)]="timeout" type="number" id="timeout" class="form-control" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="backdrop">
              Backdrop (0.0 - 1.0)
            </label>
            <input [(ngModel)]="backdrop" type="number" id="backdrop" class="form-control" min="-1" max="1" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="position">Position</label>
                <select [(ngModel)]="position" id="position" class="form-control">
                  <option [ngValue]="'leftTop'">LEFT - TOP</option>
                  <option [ngValue]="'leftCenter'">LEFT - CENTER</option>
                  <option [ngValue]="'leftBottom'">LEFT - BOTTOM</option>
                  <option [ngValue]="'rightTop'">RIGHT - TOP</option>
                  <option [ngValue]="'rightCenter'">RIGHT - CENTER</option>
                  <option [ngValue]="'rightBottom'">RIGHT - BOTTOM</option>
                  <option [ngValue]="'centerTop'">CENTER - TOP</option>
                  <option [ngValue]="'centerCenter'">CENTER - CENTER</option>
                  <option [ngValue]="'centerBottom'">CENTER - BOTTOM</option>
                </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="switch-group-wrapper">
            <div class="switch-wrapper">
              <strong>Show progress bar?</strong>
              <div class="switch">
                <input [(ngModel)]="progressBar" id="progressBar" class="cmn-toggle cmn-toggle-yes-no" type="checkbox" />
                <label for="progressBar" data-on="On" data-off="Off"></label>
              </div>
            </div>
            <div class="switch-wrapper">
              <strong>Close on click?</strong>
              <div class="switch">
                <input [(ngModel)]="closeClick" id="closeClick" class="cmn-toggle cmn-toggle-yes-no" type="checkbox" />
                <label for="closeClick" data-on="On" data-off="Off"></label>
              </div>
            </div>
            <div class="switch-wrapper">
              <strong>Pause on hover?</strong>
              <div class="switch">
                <input [(ngModel)]="pauseHover" id="pauseHover" class="cmn-toggle cmn-toggle-yes-no" type="checkbox" />
                <label for="pauseHover" data-on="On" data-off="Off"></label>
              </div>
            </div>
            <div class="switch-wrapper">
              <strong>New items on top?</strong>
              <div class="switch">
                <input [(ngModel)]="newTop" id="newTop" class="cmn-toggle cmn-toggle-yes-no" type="checkbox" />
                <label for="newTop" data-on="On" data-off="Off"></label>
              </div>
            </div>
            <div class="switch-wrapper">
              <strong>Filter duplicates?</strong>
              <div class="switch">
                <input
                  [(ngModel)]="filterDuplicates"
                  id="filterDuplicates"
                  class="cmn-toggle cmn-toggle-yes-no"
                  type="checkbox"
                />
                <label for="filterDuplicates" data-on="On" data-off="Off"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="dockMax">
              Max on screen
            </label>
            <input [(ngModel)]="dockMax" type="number" id="dockMax" class="form-control" min="1" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="blockMax">
              Max at position
            </label>
            <input [(ngModel)]="blockMax" type="number" id="blockMax" class="form-control" min="1" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="style">Toast Style</label>
            <select [(ngModel)]="style" id="style" class="form-control">
                  <option [ngValue]="'material'">Material</option>
                  <option [ngValue]="'dark'">Dark</option>
                  <option [ngValue]="'simple'">Simple</option>
                </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="buttons">
              <div class="btn-group btn-group-justified">
                <div class="btn btn-success" (click)="onSuccess()">Success</div>
                <div class="btn btn-info" (click)="onInfo()">Info</div>
                <div class="btn btn-danger" (click)="onError()">Error</div>
                <div class="btn btn-warning" (click)="onWarning()">Warning</div>
              </div>
              <div class="btn-group btn-group-justified">
                <div class="btn btn-default" (click)="onSimple()">Simple</div>
                <div class="btn btn-blue" (click)="onAsyncLoading()">Async</div>
                <div class="btn btn-teal" (click)="onConfirmation()">Confirm</div>
                <div class="btn btn-black" (click)="onPrompt()">Prompt</div>
              </div>
              <div class="btn-group btn-group-justified">
                <div class="btn btn-default" (click)="onHtml()">HTML</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn btn-block btn-primary" (click)="onClear()">Clear all</div>
    </app-card>
  </div>
</div>
<ng-snotify [ngClass]="style"></ng-snotify>
