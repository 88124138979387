<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <div class="row" style="display: flex;justify-content: center;">
    <img src="assets/images/icon-gif/1_zVnWJtyGOX_kUIDm6ccCfQ.gif" style="width: 350px;" alt="">
  </div>
  <div class="row" style="display: flex;justify-content: center;">
    <h3>----- {{'EXECUTIVE.UNDER-DEVELOPMENT' | translate}} -----</h3>
  </div>
  <!-- style="display: none;" -->
  <app-card [hidHeader]="true" cardClass="user-profile-list" style="display: none;">
    <div class="dt-responsive table-responsive">
      <table datatable class="table table-striped row-border table-hover">
        <thead>
          <tr>
            <th>{{'TEACHER.PROFILE.PERSONAL-INFORMATION' | translate}}</th>
            <th>{{'EXECUTIVE.REGISTER-DATE' | translate}}</th>
            <th>{{'EXECUTIVE.LAST-ACCESS-STATUS' | translate}}</th>
            <th>{{'TEACHER.STATUS' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="d-inline-block align-middle">
                <img src="assets/images/user/avatar-1.jpg" alt="user image" class="img-radius align-top m-r-15"
                  style="width:40px;">
                <div class="d-inline-block">
                  <h6 class="m-b-0">Quinn Flynn</h6>
                  <p class="m-b-0">Qf@domain.com</p>
                </div>
              </div>
            </td>
            <td>2011/04/25</td>
            <td>
              <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-success"></i>{{'EXECUTIVE.ONLINE' | translate}}</span>
              <!-- <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-warning"></i>2 hours ago</span>
              <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-danger"></i>2011/04/25</span> -->
            </td>
            <td>
              <span class="badge badge-light-warning">{{'EXECUTIVE.GENERAL-PERSON' | translate}}</span>
              <!-- <span class="badge badge-light-success">นักเรียน</span> -->
            </td>
          </tr>  
          <tr>
            <td>
              <div class="d-inline-block align-middle">
                <img src="assets/images/user/avatar-1.jpg" alt="user image" class="img-radius align-top m-r-15"
                  style="width:40px;">
                <div class="d-inline-block">
                  <h6 class="m-b-0">Quinn Flynn</h6>
                  <p class="m-b-0">Qf@domain.com</p>
                </div>
              </div>
            </td>
            <td>2011/04/25</td>
            <td>
              <!-- <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-success"></i>กำลังใช้งาน</span> -->
              <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-warning"></i>2 hours ago</span>
              <!-- <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-danger"></i>2011/04/25</span> -->
            </td>
            <td>
              <span class="badge badge-light-warning">{{'EXECUTIVE.GENERAL-PERSON' | translate}}</span>
              <!-- <span class="badge badge-light-success">นักเรียน</span> -->
            </td>
          </tr>  
          <tr>
            <td>
              <div class="d-inline-block align-middle">
                <img src="assets/images/user/avatar-1.jpg" alt="user image" class="img-radius align-top m-r-15"
                  style="width:40px;">
                <div class="d-inline-block">
                  <h6 class="m-b-0">Quinn Flynn</h6>
                  <p class="m-b-0">Qf@domain.com</p>
                </div>
              </div>
            </td>
            <td>2011/04/25</td>
            <td>
              <!-- <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-success"></i>กำลังใช้งาน</span> -->
              <!-- <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-warning"></i>2 hours ago</span> -->
              <span><i class="fas fa-circle text-c-blue f-10 m-r-5 text-danger"></i>2011/04/25</span>
            </td>
            <td>
              <!-- <span class="badge badge-light-primary">บุคคลทั่วไป</span> -->
              <span class="badge badge-light-success">{{'EXECUTIVE.STUDENT' | translate}}</span>
            </td>
          </tr>  
      </table>
    </div>
  </app-card>
</app-card>