import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { SigninInterface } from '../interfaces/auth-interface/signin-interface';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TeaCherSharedDataService } from '../common/TeaCherSharedDataService';

@Injectable({
  providedIn: 'root',
})
export class ServiceManagerService {
  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  JWT_Token?: string;

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  signinInterface: SigninInterface;
  constructor(
    private httpClient: HttpClient,
    private teaCherSharedDataService: TeaCherSharedDataService
  ) {
  }

  jwttokenk() {
    this.signinInterface = JSON.parse(localStorage.getItem('currentUserLogin'));
    if (this.signinInterface != null) {
      return this.signinInterface.token;
    }
  }

  getPortalId(): string {
    var portalId = 0;
    var localPortal = localStorage.getItem("select_portal");
    var localLogin = localStorage.getItem("currentUserLogin");
    if (localPortal != null) {
      var dataLocalSelectPortal = JSON.parse(localPortal);
      portalId = dataLocalSelectPortal.value;
    } else {
      if (localLogin != null) {
        var dataLocalSelectPortal = JSON.parse(localLogin);
        portalId = dataLocalSelectPortal.portalId;
      }
    }
    return portalId.toString();
  }

  async HTTP_GET(API_URL: string) {
    this.JWT_Token = await this.jwttokenk();
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(API_URL, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'PortalId': this.getPortalId(),
            Authorization: `bearer ${this.JWT_Token}`,
          }),
          responseType: 'json',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async HTTP_POST(API_URL: string, data: any) {
    this.JWT_Token = await this.jwttokenk();
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'PortalId': this.getPortalId(),
            Authorization: `bearer ${this.JWT_Token}`,
          }),
          responseType: 'json',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  HTTP_POST_AUTH(API_URL: string, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(API_URL, data, { headers: this.headers, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  HTTP_POST_IMAGE(API_URL: string, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(API_URL, data, {
          headers: new HttpHeaders({
            'PortalId': this.getPortalId(),
            Authorization: `bearer ${this.JWT_Token}`,
          }),
          responseType: 'json',
          reportProgress: true,
          observe: 'events',
        })
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event.type == HttpEventType.UploadProgress) {

              this.teaCherSharedDataService.changeUploadfileProgress(
                Math.round((100 / event.total) * event.loaded)
              );
              // this.progress = Math.round((100 / event.total) * event.loaded);
            } else if (event.type == HttpEventType.Response) {
              this.teaCherSharedDataService.changeUploadfileProgress(null);

              resolve(event.body);
              // this.progress = null;
            }
          }),
          catchError((err: any) => {
            // this.progress = null;
            reject(err);
            // alert(err.message);
            return throwError(err.message);
          })
        ).subscribe();
    });
  }

  // HTTP_GET(API_URL: string): Observable<repos[]> {
  //   return this.httpClient.get<repos[]>(API_URL, { 'headers': this.headers, responseType: "json" })
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       }),
  //       catchError((err, caught) => {
  //         console.error(err);
  //         throw err;
  //       }
  //       )
  //     )
  // }

  // HTTP_POST(API_URL: string,body:any): Observable<any> {
  //   return this.httpClient.post(API_URL,body, { 'headers': this.headers, responseType: "json" })
  //     .pipe(
  //       map((response) => {
  //         return response;
  //       }),
  //       catchError((err, caught) => {
  //         console.error(err);
  //         throw err;
  //       }
  //       )
  //     )
  // }
}
