<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <!-- <div class="row" style="display: flex;justify-content: center;">
    <img src="assets/images/icon-gif/1_zVnWJtyGOX_kUIDm6ccCfQ.gif" style="width: 350px;" alt="">
  </div>
  <div class="row" style="display: flex;justify-content: center;">
    <h3>----- อยู่ระหว่างการพัฒนา -----</h3>
  </div> -->
  <!-- style="display: none;" -->
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div style="justify-content: space-between;display: flex;">
        <div>
          <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
            title="{{'EXECUTIVE.DOWNLOAD-REPORT-A-USAGE-PROBLEM' | translate}}"
            style="margin-bottom: 10px;margin-right: 30px;float: right;" (click)="exportexcel('Report-problem')">
            <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
          </button>
        </div>
        <div>
          <label style="margin-right: 5px;"><b>Search : </b></label>
          <input type="date" id="start" name="bday" (change)="clearDateEnd()" [(ngModel)]="startDate">
          <label style="margin-right: 5px;margin-left: 5px;"><b>to</b></label>
          <input type="date" id="end" name="bday" [min]="startDate" [value]="endDate" (change)="searchDate($event)">
          <button type="button" class="btn btn-icon btn-warning"
            style="border-radius: 50%;width: 35px;margin-left: 10px;" (click)="clearDateAll()">
            <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i></button>
        </div>

        <script>
          function myFunction() {
            var x = document.getElementById("start").min;
            document.getElementById("end").innerHTML = x;
          }
        </script>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <app-card [hidHeader]="true" cardClass="user-profile-list">
        <div class="dt-responsive table-responsive" *ngIf="dataProblem != null && dataProblem != ''">
          <table datatable class="table table-striped row-border table-hover" *ngIf="!dataLoading">
            <thead>
              <tr>
                <th>{{'EXECUTIVE.REPORTERS-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.TOPIC' | translate}}</th>
                <th>{{'EXECUTIVE.TIME' | translate}}</th>
                <th>{{'EXECUTIVE.SELECTION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let problem of dataProblem">
                <td>
                  <div class="d-inline-block align-middle">
                    <img src="{{baseURL}}/{{problem.dataStudent.image}}" alt="user image"
                      class="img-radius align-top m-r-15" style="width:40px;height: 40px;object-fit: cover;"
                      onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <h6 class="m-b-0">{{problem.dataStudent.fullNameTh}}</h6>
                      <p class="m-b-0">{{problem.dataStudent.fullNameEn}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p style="text-overflow: ellipsis;overflow: hidden;width: 200px;">{{problem.title}}</p>
                </td>
                <td>{{problem.date | date:'short'}}</td>
                <td>
                  <span class="badge badge-light-success">{{'EXECUTIVE.MORE-INFORMATION' | translate}}</span>
                  <div class="overlay-edit">
                    <button type="button" class="btn btn-icon btn-success" (click)="viewProblembyid(problem)"><i
                        class="feather icon-eye"></i></button>
                  </div>
                </td>
              </tr>
          </table>
        </div>
        <p *ngIf="!dataLoading && dataProblem == null" style="text-align: center;padding-top: 20px;">
          <b>*{{'EXECUTIVE.NO-DATA-SHOWING' | translate}}</b></p>
        <div style="text-align: center;margin: 20px;">
          <div class="spinner-border text-info" role="status" *ngIf="dataLoading">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</app-card>

<app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">{{'EXECUTIVE.USAGER-PROBLEM-TOPIC' | translate}}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="myLargeModalLabel.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body" *ngIf="dataView != null && dataView != ''">
    <app-gallery *ngIf="type == 'images'" [albums]="singleGallery" style="text-align: center;"></app-gallery>
    <div style="text-align: center;">
      <video class="video-play-couse" *ngIf="type == 'videos'" controls style="max-width: 350px;text-align: center;">
        <source src="{{baseURL}}/{{dataView.img}}" type="video/mp4" />
      </video>
    </div>
    <h6>{{'EXECUTIVE.PROBLEM-DETAIL' | translate}}</h6>
    <p style="overflow-wrap: anywhere;">{{dataView.description}}</p>
  </div>
</app-ui-modal>

<table witdh="100%" border="1" class="table" #table11 id="excel-table-Report-problem" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REPORT-A-USAGE-PROBLEM' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'ADMIN.MEMBER.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.TOPIC' | translate}}</th>
      <th>{{'ADMIN.COURSE.DETAILS' | translate}}</th>
      <th>{{'EXECUTIVE.TIME' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let problem of dataProblem">
      <td>{{problem.dataStudent.fullNameTh}}</td>
      <td>{{problem.dataStudent.fullNameEn}}</td>
      <td>
        {{problem.dataStudent.phoneNumber[0]}}{{problem.dataStudent.phoneNumber[1]}}{{problem.dataStudent.phoneNumber[2]}}
        -
        {{problem.dataStudent.phoneNumber[3]}}{{problem.dataStudent.phoneNumber[4]}}{{problem.dataStudent.phoneNumber[5]}}
        -
        {{problem.dataStudent.phoneNumber[6]}}{{problem.dataStudent.phoneNumber[7]}}{{problem.dataStudent.phoneNumber[8]}}{{problem.dataStudent.phoneNumber[9]}}
      </td>
      <td>{{problem.title}}</td>
      <td>{{problem.description}}</td>
      <td>{{problem.date | date:'short'}}</td>
    </tr>
  </tbody>
</table>