import { Constants } from '../../constants/constant';
import { FetchDataMenberInterface } from '../../interfaces/admin-interface/fetch-data-menber-interface';
import { Injectable } from '@angular/core';
import { ServiceManagerService } from '../service-manager.service';
import { AddCourseCatgoryInterface } from '../../interfaces/admin-interface/add-course-catgory-interface';
import { CatgoryModel } from '../../models/catgoryModel';
import { ResCourseCatgoryInterface } from '../../interfaces/admin-interface/fetch-course-catgory-interface';
import { AddMemberUserInterface } from '../../interfaces/admin-interface/add-member';
import { GetSubjectTeacherInterface } from '../../interfaces/teacher-interface/get-subject-teacher-interface';
import { ResponseFindThreadsModel } from '../../models/response_find_threads.model';
import { ResponseFindCourseStudentInterface } from '../../../core/interfaces/admin-interface/response-find-course-student-interface';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private serviceManagerService: ServiceManagerService) { }

  GetFetchDataMembers() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FetchDataMembers`;
    return new Promise<FetchDataMenberInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  PostAddCourseCatgory(data: CatgoryModel) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/AddOrUpdateCategory`;
    return new Promise<AddCourseCatgoryInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteCategory(id: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/DeleteCategory?idCategory=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddSubject(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/AddOrUpdateSubject`;
    return new Promise<FetchDataMenberInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetCategory() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/Category`;
    return new Promise<FetchDataMenberInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetFetchDataCourseCatgory() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/Category`;
    return new Promise<ResCourseCatgoryInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  PostMemberAdmin(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/register`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_AUTH(API_URL, data).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetFetchDataAboutMe() {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/GetAboutMeList`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddDataAboutMe(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/AddAboutMe`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateDataAboutMe(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/UpdateAboutMe`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteDataAboutMe(aboutmeId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/DeleteAboutMe?id=${aboutmeId}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  uploadFiles(files: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UploadMedia/uploadFile`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_IMAGE(API_URL, files).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddPlaCard(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/AddPlaCard`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdatePlaCard(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/UpdatePlaCard`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  fetchDataAllMemmerUser() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FetchDataMembers`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetUserListAll_() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetUserListAll`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  GetUserListByDepartment_() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetUserListByDepartment`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  GetSubjectAndCounStudentLearnList_(idTeacher: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/SubjectAndCounStudentLearnList?teacherId=${idTeacher}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  GetPlaCardList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/GetPlaCardList`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetFetchDataContactUs() {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/GetContactUsList`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeletePlaCardId(plaCardId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/DeletePlaCard?id=${plaCardId}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddDataContact(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/AddContactUs`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateContactUs(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/UpdateContactUs`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateStatusContactUs(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/UpdateStatusContactUs`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteDataContact(contactId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/DeleteContactUs?id=${contactId}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  findDataAddress() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Provinces`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  findDataAddressDistrict(idProvinces: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Amphures?idProvinces=${idProvinces}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  findDataAddressSubDistrict(idAmphures: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Tambons?idAmphures=${idAmphures}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getZipCode(idTambons: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/ZipCode?idTambons=${idTambons}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetFetchDataRelateOrg() {
    let API_URL = `${Constants.REST_API_MAIN}/api/RelatedOrg/GetRelatedOrgList`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddDataRelateOrg(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/RelatedOrg/AddRelatedOrg`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteDataRelateOrg(orgId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/RelatedOrg/DeleteRelatedOrg?id=${orgId}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetSiteReferenceListByAdmin(id: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/GetSiteReferenceListByTeacherOrAdmin?id=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddSiteReference(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/AddSiteReference`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateSiteReference(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/UpdateSiteReference`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateStudent(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateStudent`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteSiteReference(id: number) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/DeleteSiteReference?id=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetFetchDataBankAccount() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Bank/FindBank`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateTeacher(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateTeacher`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddDataBankAccount(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Bank/InsertBank`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateExecutive(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateExecutive`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteDataBankAccount(bankId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Bank/RemoveBank?bankId=${bankId}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindTransferByStatusPay(status: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Transfer/FindTransferByStatusPay?status=${status}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateStatusTransferBuycourse(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Transfer/UpdateStatusTransferBuycourse`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateAdmin(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateAdmin`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetSubjectByIdTeacher(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/GetSubjectByTeacher?teacherID=${teacherID}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  findThreads() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreads`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  ReportSubjectAllTeacher(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindSubjectAllAndRatingList?teacherId=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  insertThreadsLikeAndRemove(likeData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsLikeAndRemove`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, likeData).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  removeThreads(threadId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreads?threadId=${threadId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  TopRatingSubjectList(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopRatingSubjectList?teacherId=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  category() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/Category`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  insertThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreads`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreads`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  findCommentsThreads(threadsId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreadsComment?threadsId=${threadsId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  removeCommentsThreads(commentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreadsComment?thdMentId=${commentId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateGetAnswer(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsCommentGetAnswer`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindDataUser(UserID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataUser?UserID=${UserID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindSudentBuyCourses(Idteacher: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindSudentBuyCourses?teacherId=${Idteacher}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindIncomeTotalInSubjectList(Idteacher: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindIncomeTotalInSubjectList?teacherId=${Idteacher}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  FindTopIncomeSubjectList(IdSubject: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopIncomeSubjectList?teacherId=${IdSubject}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindSudentBuyCoursesList(studentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindSudentListCourse?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindCourseStudentList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindCourseStudentList`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  FindSudentlearnCourseList(LearnId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindSudentlearnCourseList?studentId=${LearnId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  RemoveUser(IdUser: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/RemoveUser?UserID=${IdUser}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  InsertCertificate(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/InsertCertificate`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindCertificate() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/FindCertificate`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  UpdateCertificate(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/UpdateCertificate`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindIncomeBetweenThisMonth() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindIncomeBetweenThisMonth`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddUserManual(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UserManual/AddUserManual`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetUserManualList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/UserManual/GetUserManualList`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindStudentAndTeacherAllUse() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindStudentAndTeacherAllUse`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  UpdateUserManual(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UserManual/UpdateUserManual`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindOrderCourseList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindOrderCourseList`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  findStudentCertificate(studentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/FindCertificateStudent?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  DeleteUserManual(Id: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UserManual/DeleteUserManual?id=${Id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindCourseSubjectByStudent(studentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/FindCourseSubjectByStudent?studentID=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindSatisfindQuestion() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/FindSatisfindQuestion`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindStudentCommentRating(studentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindStudentCommentRating?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  RemoveSatisfindQuestion(Id: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/RemoveSatisfindQuestion?satisfindQuestionId=${Id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindTopRatingSystem() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopRatingSystem`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  InsertSatisfindQuestion(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/InsertSatisfindQuestion`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindSatisfactionQuestionnaire() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/FindSatisfactionQuestionnaire`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindTopIncomeSystem() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopIncomeSystem`;
    return new Promise<ResponseFindCourseStudentInterface>(
      (resolve, reject) => {
        this.serviceManagerService.HTTP_GET(API_URL).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  GetAllSubject() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/GetAllSubject`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindStudentTest(studentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportTestExam?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindReportProblem() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportProblem`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindPostTestStudent(subjectID: string, studenID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindPostTest?subjectID=${subjectID}&studenID=${studenID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindPreTestAndPostTestByidStudent(subjectID: string, studenID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindPreTestAndPostTestByidStudent?subjectID=${subjectID}&studentID=${studenID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetStudentRegisterList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetStudentRegisterList`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateStudentApprove(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateStudentApprove?stdntId=${data.stdntId}&status=${data.status}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  InsertExamPlacementTestAndQuestion(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/InsertExamPlacementTestAndQuestion`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindExamPlacementTestAndQuestion() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamPlacementTestAndQuestion`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateExamPlacementTestOnly(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateExamPlacementTestOnly`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  UpdateQuestionPlacementTestOnly(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateQuestionPlacementTestOnly`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  RemoveQuestionPlacementTest(questionLevelId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/RemoveQuestionPlacementTest?questionLevelId=${questionLevelId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  RemoveExamPlacementTest(examId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/RemoveExamPlacementTest?levelId=${examId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  InsertExamLevelAndQuestionLeveltestOnlyOne(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/InsertExamLevelAndQuestionLeveltestOnlyOne`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindResultExamPlacementTestAll() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindResultExamPlacementTestAll`;
    return new Promise<FetchDataMenberInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // FindResultExamLevelTestAllById(idlevelTest:any) {
  //   let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindResultExamLevelTestAllById?testLevelId=${idlevelTest}`;
  //   return new Promise<FetchDataMenberInterface>((resolve, reject) => {
  //     this.serviceManagerService.HTTP_GET(API_URL).then(response => {
  //       resolve(response);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }

  FindExamLevelTestMain() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamLevelTestMain`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  InsertExamLevelTestMain(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/InsertExamLevelTestMain`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  UpdateExamLevelTestMain(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateExamLevelTestMain`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  RemoveExamLevelTestMain(levelmainId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/RemoveExamLevelTestMain?levelmainId=${levelmainId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  FindResultExamLevelTestAllById(levelId: number) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindResultExamLevelTestAllById?testLevelId=${levelId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  GetDataRoleAll() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/GetDataRoleAll`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  AddOrUpdateRole(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/AddOrUpdateRole`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  RemoveRole(roleId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/RemoveRole?roleId=${roleId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetDepartmentList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetDepartmentList`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetPortal() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Portal/GetPortal`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  GetPortalType(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Portal/GetPortalType?portalId=${data.portalId}&roleId=${data.roleId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  UpdateUserPortal(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Portal/UpdateUserPortal`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  AddPortalOrUpdate(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Portal/AddPortalOrUpdate`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  RemovePortal(portalId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Portal/RemovePortal?portalId=${portalId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
