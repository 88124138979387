import { Injectable } from '@angular/core';
import { Constants } from '../../constants/constant';
import { ServiceManagerService } from '../service-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ReportAllService {

  constructor(private serviceManagerService: ServiceManagerService) { }

  GetFindReportExamSubject_(subjectId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportExamSubject?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFindReportStudentGraduateTheCourse_(subjectId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportStudentGraduateTheCourse?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  GetFindReportCountStudentAndCertificate_(subjectId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportCountStudentAndCertificate?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindReportStudentInformationStudying(subjectId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindReportStudentInformationStudying?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  

  findAllStudentCertificate(studentId:any){
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/FindCertificateStudent?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  findCertificate() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/FindCertificate`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  FindPostTestBySubject(subjectId: string,studentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindPostTestBySubject?subjectId=${subjectId}&studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindLearnStudentBySubject(subjectId: string,studentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindLearnStudentBySubject?subjectId=${subjectId}&studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

}
