<app-card cardTitle="" cardClass="card-datatable" [options]="false" [hidHeader]="true">
  <br>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <app-card [hidHeader]="true" cardClass="user-profile-list">
        <div class="dt-responsive table-responsive" *ngIf="dataSatisfactionQuestionnaire != undefined ">
          <table datatable class="table table-striped row-border table-hover" *ngIf="!dataLoading">
            <thead>
              <tr>
                <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.CLASSIFICATION-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.TEACHER-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.AMOUNT-OF-PEOPLE-ANSWERING-THE-QUESTION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataSatisfactionQuestionnaire;let i = index" (click)="openViewSummary(item.subjectData.subjectId)">
                <td>
                  <div class="d-inline-block align-middle">
                    <img src="{{baseURL}}{{item.subjectData.images}}" alt="user image" class="img-radius align-top m-r-15"
                      style="width:40px;height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <h6 class="m-b-0">{{item.subjectData.name_th}}</h6>
                      <p class="m-b-0">{{item.subjectData.name_en}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    <h6 class="m-b-0">{{item.subjectData.category.nameTh}}</h6>
                    <p class="m-b-0">{{item.subjectData.category.nameEn}}</p>
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    <h6 class="m-b-0">{{item.subjectData.teacher.fullNameTh}}</h6>
                    <p class="m-b-0">{{item.subjectData.teacher.fullNameEn}}</p>
                  </div>
                </td>
                <td>
                  <span style="font-size: 18px;" class="badge badge-light-success"><strong>{{item.respondents}} {{'EXECUTIVE.PERSON' | translate}}</strong></span>
                  <div class="overlay-edit">
                    <button type="button" class="btn btn-icon btn-success" ><i
                        class="feather icon-eye"></i></button>{{'EXECUTIVE.SEE-MORE' | translate}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="text-align: center;margin: 20px;">
          <div class="spinner-border text-info" role="status" *ngIf="dataLoading">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div>
        <!-- <div class="row" style="display: flex;justify-content: center;">
          <img src="assets/images/icon-gif/1_zVnWJtyGOX_kUIDm6ccCfQ.gif" style="width: 350px;" alt="">
        </div>
        <div class="row" style="display: flex;justify-content: center;">
          <h2>----- อยู่ระหว่างการพัฒนา -----</h2>
        </div> -->
      </app-card>
    </div>
  </div>
</app-card>
<app-view-summary></app-view-summary>