import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss']
})
export class ReportProblemComponent implements OnInit {
  public singleGallery: any;
  dataProblem: any;
  dataProblemFilter: any;
  dataView: any;
  baseURL = Constants.REST_API_MAIN;
  dataLoading: boolean = true;
  type: string;
  startDate: any;
  endDate: any;
  @ViewChild('myLargeModalLabel') myLargeModalLabel;

  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
  }

  ngOnInit() {
    this.findReportProblem();
    this.onChangSelectPortal();
  }

  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.findReportProblem();
      }
    });
  }

  findReportProblem() {
    this.dataLoading = true;
    this.adminService.FindReportProblem().then(res => {
      if (res.status == true) {
        this.dataProblem = res.data;
        this.dataProblemFilter = res.data;
        this.dataLoading = false;
      } else {
        this.dataProblemFilter = null;
        this.dataProblem = null;
        this.dataLoading = false;
      }

    }, error => {
      console.log("error : ", error);
    })
  }
  viewProblembyid(data: any) {
    this.myLargeModalLabel.show();
    this.dataView = data;
    var nameImg = data.img.split('\\');
    if (nameImg != undefined && data.img != '') {
      var dataImg = nameImg[4];
      this.type = nameImg[3];
    }

    this.singleGallery = [
      {
        src: this.baseURL + '/' + data.img,
        caption: dataImg,
        thumb: this.baseURL + '/' + data.img,
      },
    ];
  }
  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

  clearDateAll() {
    this.startDate = document.getElementById('start');
    this.endDate = document.getElementById('end');
    this.findReportProblem();
  }

  clearDateEnd() {
    this.endDate = "YYYY-MM-DD";
  }

  searchDate(dateEnd: any) {
    this.dataProblem = this.dataProblemFilter;
    var filterDataDate = this.dataProblem.filter(f => moment(f.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
    this.dataProblem = filterDataDate;
  }
}
