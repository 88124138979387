import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-summary',
  templateUrl: './view-summary.component.html',
  styleUrls: ['./view-summary.component.scss'],
})
export class ViewSummaryComponent implements OnInit {
  @ViewChild('viewFormSummary') viewFormSummary;
  dataLanguage: any;
  constructor(
    private adminService: AdminService,
    public translate: TranslateService
  ) {}
  datafindQuestion: any = [];
  subjectID: any;
  dtOptions: DataTables.Settings = {};
  subjectName: string;
  questionData: any;
  counting: any;
  ngOnInit() {
    this.getLanguage();
    this.dtOptions = {
      paging: false,
      processing: true,
      ordering: false,
      searching: false,
      info: false,
    };
  }
  getLanguage(){
    this.dataLanguage = localStorage.getItem('lang');
  }

  public openViewSummary(subjectId, data) {
    this.datafindQuestion = [];
    this.viewFormSummary.show();
    this.subjectID = subjectId;
    this.datafindQuestion.push(
      data.find((x) => x.subjectData.subjectId == subjectId)
    );
    this.subjectName = this.datafindQuestion[0].subjectData.name_th;
    this.questionData = this.datafindQuestion[0].surveyData;
    this.counting = this.datafindQuestion[0].respondents;
  }

  checkedOnOff(data: any, id: any) {
    if (data.currentTarget.checked) {
      this.removeSatisfindQuestion(id);
    } else {
      this.removeSatisfindQuestion(id);
    }
  }

  removeSatisfindQuestion(id: any) {
    this.adminService.RemoveSatisfindQuestion(id).then((res) => {
      for (let i = 0; i < this.datafindQuestion.length; i++) {
        if (this.datafindQuestion[i].satisfindQuestionId == id) {
          this.datafindQuestion[i].showType = res.showType;
        }
      }
    });
  }
  remove(id: any, i) {
    this.getLanguage();
    Swal.fire({
      title:
        this.dataLanguage == 'th'
          ? 'ต้องการลบข้อมูลใช่หรือไม่?'
          : 'Want to delete the data?',
      text:
        this.dataLanguage == 'th'
          ? 'โปรดตรวจสอบข้อมูลให้ถูกต้องก่อนลบข้อมูล!'
          : 'Please make sure the information is correct before deleting!',
      imageUrl: 'assets/images/icon-gif/remove1.gif',
      imageHeight: 130,
      confirmButtonColor: '#4099FF',
      showCancelButton: true,
      confirmButtonText: this.dataLanguage == 'th' ? 'ยืนยัน' : 'Confirm',
      cancelButtonColor: '#bdc3c7',
      cancelButtonText: this.dataLanguage == 'th' ? 'ยกเลิก' : 'Cancel',
      allowOutsideClick: false,
    }).then((willDelete) => {
      if (willDelete.value) {
        this.adminService.RemoveSatisfindQuestion(id).then((res) => {
          if (res.status == true) {
            this.datafindQuestion.splice(i, 1);
            Swal.fire('สำเร็จ!', '', 'success');
          }
        });
      }
    });
  }

  findSatisfindQuestion() {
    this.adminService.FindSatisfindQuestion().then((res) => {
      this.datafindQuestion = res.data;
    });
  }
}
function getLanguage() {
  throw new Error('Function not implemented.');
}

