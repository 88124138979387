<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <!-- List Start -->
  <div class="row">
    <div class="col-sm-7">
      <h5>
        {{'EXECUTIVE.MEMBER-INFORMATION' | translate}}
      </h5>
    </div>
    <div class="col-sm-5">
      <div class="form-group">
        <select title="Customer" name="customer" class="mb-3 form-control"
          *ngIf="openTeacher == true || openStudent == true" [(ngModel)]="selectUser"
          (change)="clickOpenTeacherAndStudent()">
          <option value="ผู้สอน">{{'EXECUTIVE.INSTRUCTOR' | translate}}</option>
          <option value="ผู้เรียน">{{'EXECUTIVE.LEARNER' | translate}}</option>
        </select>
      </div>
    </div>
  </div>
  <!-- List end -->

  <!-- List User Start-->
  <div class="row">
    <div class="col-sm-12">
      <button *ngIf="openTeacher == true" type="button" class="btn btn-primary" data-toggle="tooltip"
        data-placement="bottom" title="{{'EXECUTIVE.DOWNLOAD-EXCEL-INSTRUCTOR' | translate}}"
        (click)="exportexcel('Instructor')">{{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}</button>
      <button *ngIf="openStudent == true" type="button" class="btn btn-primary" data-toggle="tooltip"
        data-placement="bottom" title="{{'EXECUTIVE.DOWNLOAD-EXCEL-LEARNER' | translate}}"
        (click)="exportexcel('Learner')">{{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}</button>

      <div style="text-align: end;">
        <label style="margin-right: 5px;"><b>Search : </b></label>
        <input type="date" id="start" name="bday" (change)="clearDateEnd()" [(ngModel)]="startDate">
        <label style="margin-right: 5px;margin-left: 5px;"><b>to</b></label>
        <input type="date" id="end" name="bday" [min]="startDate" [value]="endDate" (change)="searchDate($event)">
        <button type="button" class="btn btn-icon btn-warning" style="border-radius: 50%;width: 35px;margin-left: 10px;"
          (click)="clearDateAll()">
          <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i></button>
      </div>

      <script>
        function myFunction() {
          var x = document.getElementById("start").min;
          document.getElementById("end").innerHTML = x;
        }
      </script>

      <app-card [hidHeader]="true" cardClass="user-profile-list">
        <!-- Teacher List Start-->
        <div *ngIf="openTeacher == true">
          <div class="dt-responsive table-responsive"
            *ngIf="TeacherAndStudent != null && TeacherAndStudent != '' && TeacherAndStudent != undefined ">
            <table datatable class="table table-striped row-border table-hover " *ngIf="!dataLoading1">
              <thead>
                <tr>
                  <th>{{'EXECUTIVE.NAME' | translate}}</th>
                  <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
                  <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                  <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                  <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of TeacherAndStudent.dataTeachers;index as i">
                  <td>
                    <div class="d-inline-block align-middle">
                      <img src="{{urlImage}}{{item.image}}" alt="user image" class="img-radius align-top m-r-15"
                        style="width:40px; height: 40px;object-fit: cover;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block">
                        <h6 class="m-b-0">{{item.fullNameTh}}</h6>
                        <p class="m-b-0">{{item.fullNameEn}}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{item.username}}</td>
                  <td>{{item.email}}</td>
                  <td>*******{{item.phoneNumber[7]}}{{item.phoneNumber[8]}}{{item.phoneNumber[9]}}</td>
                  <!-- <td >{{item.phoneNumber}}</td> -->
                  <td>{{item.brithOfDate | date:'fullDate'}}</td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;margin: 20px;">
              <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
              </div>
            </div>
          </div>
        </div>
        <!--Student List Start -->
        <div *ngIf="openStudent == true">
          <div class="dt-responsive table-responsive"
            *ngIf="TeacherAndStudent != null && TeacherAndStudent != '' && TeacherAndStudent != undefined">
            <table datatable class="table table-striped row-border table-hover" *ngIf="!dataLoading1">
              <thead>
                <tr>
                  <th>{{'EXECUTIVE.NAME' | translate}}</th>
                  <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
                  <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                  <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                  <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of TeacherAndStudent.dataStudents;index as i">
                  <td>
                    <div class="d-inline-block align-middle">
                      <img src="{{urlImage}}{{item.dataStudents.image}}" alt="user image"
                        class="img-radius align-top m-r-15" style="width:40px; height: 40px;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block">
                        <h6 class="m-b-0">{{item.dataStudents.fullNameTh}}</h6>
                        <p class="m-b-0">{{item.dataStudents.fullNameEn}}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{item.dataStudents.username}}</td>
                  <td>{{item.dataStudents.email}}</td>
                  <td>
                    *******{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}
                  </td>
                  <!-- <td>{{item.dataStudents.phoneNumber}}</td> -->
                  <td>{{item.dataStudents.brithOfDate | date:'fullDate'}}</td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;margin: 20px;">
              <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
              </div>
            </div>
          </div>
        </div>
        <!--Student List End -->
      </app-card>
    </div>
  </div>
  <!-- List User end-->

  <!-- excel Learner -->
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Learner" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.NAME' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'ADMIN.MEMBER.ID-CARD' | translate}}</th>
        <th>{{'ADMIN.MEMBER.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="TeacherAndStudent != null">
      <tr *ngFor="let item of TeacherAndStudent.dataStudents;index as i">
        <td>
          <h6 class="m-b-0">{{item.dataStudents.fullNameTh}}</h6>
        </td>
        <td>{{item.dataStudents.username}}</td>
        <td>{{item.dataStudents.email}}</td>
        <td>
          {{item.dataStudents.phoneNumber[0]}}{{item.dataStudents.phoneNumber[1]}}{{item.dataStudents.phoneNumber[2]}}-{{item.dataStudents.phoneNumber[3]}}{{item.dataStudents.phoneNumber[4]}}{{item.dataStudents.phoneNumber[5]}}-{{item.dataStudents.phoneNumber[6]}}{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{item.dataStudents.brithOfDate | date:'fullDate'}}</td>
        <td>
          {{item.dataStudents.citizenId[0]}}-{{item.dataStudents.citizenId[1]}}{{item.dataStudents.citizenId[2]}}{{item.dataStudents.citizenId[3]}}{{item.dataStudents.citizenId[4]}}-{{item.dataStudents.citizenId[5]}}{{item.dataStudents.citizenId[6]}}{{item.dataStudents.citizenId[7]}}{{item.dataStudents.citizenId[8]}}{{item.dataStudents.citizenId[9]}}-{{item.dataStudents.citizenId[10]}}{{item.dataStudents.citizenId[11]}}-{{item.dataStudents.citizenId[12]}}
        </td>
        <td>{{item.dataStudents.address}}</td>
      </tr>
    </tbody>
  </table>

  <!-- excel Instructor -->
  <table witdh="100%" border="1" class="table" #table12 id="excel-table-Instructor" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.NAME' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'ADMIN.MEMBER.ID-CARD' | translate}}</th>
        <th>{{'ADMIN.MEMBER.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="TeacherAndStudent != null">
      <tr *ngFor="let item of TeacherAndStudent.dataTeachers;index as i">
        <td>
          <h6 class="m-b-0">{{item.fullNameTh}}</h6>
        </td>
        <td>{{item.username}}</td>
        <td>{{item.email}}</td>
        <td>
          {{item.phoneNumber[0]}}{{item.phoneNumber[1]}}{{item.phoneNumber[2]}}-{{item.phoneNumber[3]}}{{item.phoneNumber[4]}}{{item.phoneNumber[5]}}-{{item.phoneNumber[6]}}{{item.phoneNumber[7]}}{{item.phoneNumber[8]}}{{item.phoneNumber[9]}}
        </td>
        <td>{{item.brithOfDate | date:'fullDate'}}</td>
        <td>
          {{item.citizenId[0]}}-{{item.citizenId[1]}}{{item.citizenId[2]}}{{item.citizenId[3]}}{{item.citizenId[4]}}-{{item.citizenId[5]}}{{item.citizenId[6]}}{{item.citizenId[7]}}{{item.citizenId[8]}}{{item.citizenId[9]}}-{{item.citizenId[10]}}{{item.citizenId[11]}}-{{item.citizenId[12]}}
        </td>
        <td>{{item.address}}</td>
      </tr>
    </tbody>
  </table>
</app-card>