import { Constants } from '../../constants/constant';
import { Injectable } from '@angular/core';
import { ServiceManagerService } from '../service-manager.service';
import { GetSubjectTeacherInterface } from '../../interfaces/teacher-interface/get-subject-teacher-interface';
import { AddSiteReferenceInterfaceInterface } from '../../interfaces/teacher-interface/add-site-referenceInterface-interface'
import { ResponseCheckSubjectiveExamInterface } from '../../interfaces/teacher-interface/response-check-subjective-exam-interface'
import { ResponseFindThreadsModel } from '../../models/response_find_threads.model';
@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(private serviceManagerService: ServiceManagerService) { }

  uploadFileAndImage(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UploadMedia/uploadFile`;

    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  uploadFileAndImageContentLesson(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/UpdateContentFileLesson`;

    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindDataTeacher(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataTeacher?teacherID=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  InsertExamAndQuestion(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/InsertExamAndQuestion`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertLesson(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/AddLesson`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateQuestionOnlyOne(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateQuestionOnlyOne`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertExamAndQuestionOnlyOne(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/InsertExamAndQuestionOnlyOne`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateLesson(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/UpdateLesson`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GenTicket(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/GenTicket`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveTicket(ticketID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/RemoveTicket?ticketID=${ticketID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertLessonContentOnly(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/InsertLessonContentOnly`;

    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateConnentLesson(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/UpdateConnentLesson`;

    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveQuestion(id: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/RemoveQuestion?id=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveSubject(id: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/RemoveSubject?subjectID=${id}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  DisableSubject(id: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/DisableSubject?subjectID=${id}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveLesson(id: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/RemoveLesson?lessonID=${id}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveConnentLesson(id: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/RemoveConnentLesson?connentLessonID=${id}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindTicket(subjectID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/FindTicket?subjectID=${subjectID}`;
    return new Promise<any>((resolve, reject) => {

      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetSubjectByIdTeacher(teacherID:string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/GetSubjectByTeacher?teacherID=${teacherID}`;
    return new Promise<GetSubjectTeacherInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindExamAndQuestion(subjectID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamAndQuestion?subjectID=${subjectID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindExamAndQuestionByLessonID(lessonID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamAndQuestionByLessonID?lessonID=${lessonID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  // FindExamFinal(subjectID: any) {
  //   let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamFinal?subjectID=${subjectID}`;
  //   return new Promise<any>((resolve, reject) => {
  //     this.serviceManagerService.HTTP_GET(API_URL).then(response => {
  //       resolve(response);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }

  FindExamFinal(subjectID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamFinalByTeacher?subjectID=${subjectID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  FindExamBegin(subjectID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindExamBegin?subjectID=${subjectID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindLesson(subjectID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Lesson/FindLesson?subjectID=${subjectID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateExamFinal(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateExamFinal`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  UpdateExamBegin(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateExamBegin`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  AddSiteReference(data: AddSiteReferenceInterfaceInterface) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/AddSiteReference`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateSiteReference(data: AddSiteReferenceInterfaceInterface) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/UpdateSiteReference`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetSiteReferenceListByTeacherOrAdmin(id: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/GetSiteReferenceListByTeacherOrAdmin?id=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  DeleteSiteReference(id: number) {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/DeleteSiteReference?id=${id}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  uploadFiles(files: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UploadMedia/uploadFile`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_IMAGE(API_URL, files).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findCheckSubjectiveExam() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindCheckSubjectiveExam`;
    return new Promise<ResponseCheckSubjectiveExamInterface>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateCheckSubjectiveExam(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/UpdateCheckSubjectiveExam`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  category() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/Category`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddress() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Provinces`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddressDistrict(idProvinces:any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Amphures?idProvinces=${idProvinces}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  
  findDataAddressSubDistrict(idAmphures:any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Tambons?idAmphures=${idAmphures}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getZipCode(idTambons:any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/ZipCode?idTambons=${idTambons}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreads`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreads`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  addCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  findCommentsThreads(threadsId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreadsComment?threadsId=${threadsId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateTeacherprofile(data:any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateTeacher`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL,data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  removeCommentsThreads(commentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreadsComment?thdMentId=${commentId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  updateCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateGetAnswer(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsCommentGetAnswer`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindDataUser(UserID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataUser?UserID=${UserID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findThreads() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreads`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertThreadsLikeAndRemove(likeData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsLikeAndRemove`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, likeData).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  removeThreads(threadId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreads?threadId=${threadId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  FindIncomeTotalInSubjectList(Idteacher: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindIncomeTotalInSubjectList?teacherId=${Idteacher}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindTopIncomeSubjectList(IdSubject: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopIncomeSubjectList?teacherId=${IdSubject}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  TopRatingSubjectList(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindTopRatingSubjectList?teacherId=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindSubjectiveExam() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindSubjectiveExam`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  ReportSubjectAllTeacher(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/FindSubjectAllAndRatingList?teacherId=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindCheckQuestionFile(questionId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Exam/FindCheckQuestionFile?questionId=${questionId}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

}
