<div class="container">
  <div class="row help-desk" [ngClass]="this.size">
    <div class="col-xl-8 col-lg-12 filter-bar">
      <nav class="text-right m-b-30 p-10 justify-content-end pt-0">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="ticketSize" data-toggle="buttons" [(ngModel)]="size">
          <label ngbButtonLabel class="btn btn-primary">
            <input ngbButton type="radio" value="sm-view" id="option1" checked> <i class="feather icon-align-justify m-0"></i>
          </label>
          <label ngbButtonLabel class="btn btn-primary">
            <input ngbButton type="radio" value="md-view" id="option2"> <i class="feather icon-menu m-0"></i>
          </label>
          <label ngbButtonLabel class="btn btn-primary active">
            <input ngbButton type="radio" value="large-view" id="option3"> <i class="feather icon-grid m-0"></i>
          </label>
        </div>
      </nav>
      <div (click)="this.showView = !this.showView" class="ticket-block">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right pr-0">
                  <div class="card-body inner-center">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Piaf able</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-4">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="hd-detail.html" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View Ticket</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top" class="active"><i class="feather icon-star text-warning"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-circle text-muted"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="this.showView = !this.showView" class="ticket-block">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-4.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right">
                  <div class="card-body">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p2.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Pro able</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-2">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="javascript:" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-star text-muted"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top" class="active"><i class="feather icon-circle text-warning"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="this.showView = !this.showView" class="ticket-block">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right">
                  <div class="card-body">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p3.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">CRM admin</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-2">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="javascript:" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-star text-muted"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-circle text-muted"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="this.showView = !this.showView" class="ticket-block open-tic">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right">
                  <div class="card-body">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p4.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Alpha pro</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-2">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="javascript:" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-star text-muted"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-circle text-muted"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="this.showView = !this.showView" class="ticket-block">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right">
                  <div class="card-body">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Pro able</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-2">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="javascript:" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-star text-muted"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-circle text-muted"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div (click)="this.showView = !this.showView" class="ticket-block close-tic">
        <div class="row">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <div class="card hd-body">
              <div class="row align-items-center">
                <div class="col border-right">
                  <div class="card-body">
                    <div class="ticket-customer font-weight-bold">John lui</div>
                    <div class="ticket-type-icon private mt-1 mb-1"><i class="feather icon-lock mr-1 f-14"></i>theme customisation issue</div>
                    <ul class="list-inline mt-2 mb-0">
                      <li class="list-inline-item"><img src="assets/images/ticket/p1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">CRM admin</li>
                      <li class="list-inline-item"><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Assigned to Robert alia</li>
                      <li class="list-inline-item"><i class="feather icon-calendar mr-1 f-14"></i>Updated 22 hours ago</li>
                      <li class="list-inline-item"><i class="feather icon-message-square mr-1 f-14"></i>9</li>
                    </ul>
                    <div class="excerpt mt-2">
                      <h6><img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 avatar mr-2 rounded">Last comment from <a href="javascript:">Robert alia:</a></h6>
                      <p>hello John lui, you need to create "toolbar-options" div only once in a page&nbsp;in your code, this div fill found every "td" tag in your page, just remove those things. and
                        also in option
                        button add</p>
                    </div>
                    <div class="mt-2">
                      <a href="javascript:" class="mr-3 text-muted"><i class="feather icon-eye mr-1"></i>View</a>
                      <a href="javascript:" class="text-danger"><i class="feather icon-trash-2 mr-1"></i>Delete</a>
                    </div>
                  </div>
                </div>
                <div class="col-auto pl-0 right-icon">
                  <div class="card-body">
                    <ul class="list-unstyled mb-0">
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-star text-muted"></i></a></li>
                      <li><a href="javascript:" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top"><i class="feather icon-circle text-muted"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-auto hover-blk position-absolute shadow-sm rounded">
                  <div class="card-body p-0 pt-2">
                    <div class="img-txt">
                      <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                      <p>You replied</p>
                    </div>
                    <p>hello Yogen dra,you need to create "toolbar-options" div only once in a page in your code, this div fill found every "td"...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div class="right-side">
        <div class="card mb-3">
          <div class="card-header">
            <h5>Ticket Categories</h5>
          </div>
          <div class="card-body p-3">
            <div class="cat-list">
              <div class="border-bottom pb-3 ">
                <div class="d-inline-block">
                  <img src="assets/images/ticket/p1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Piaf able</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">1</a>
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/ticket/p2.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Pro able</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/ticket/p3.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">CRM admin</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">1</a>
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/ticket/p4.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Alpha pro</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/ticket/p5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Carbon able</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header pt-4 pb-4">
            <h5>Support Aggent</h5>
          </div>
          <div class="card-body p-3">
            <div class="cat-list">
              <div class="border-bottom pb-3 ">
                <div class="d-inline-block">
                  <img src="assets/images/user/avatar-5.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Tom Cook</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">1</a>
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/user/avatar-4.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Brad Larry</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-danger rounded-circle mr-1" data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">1</a>
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/user/avatar-3.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Jhon White</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="border-bottom pb-3 pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/user/avatar-2.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Mark Jobs</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
              <div class="pt-3">
                <div class="d-inline-block">
                  <img src="assets/images/user/avatar-1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">
                  <a href="javascript:">Able Pro</a>
                </div>
                <div class="float-right span-content">
                  <a href="javascript:" class="btn btn-default badge-secondary rounded-circle mr-0 " data-toggle="tooltip" data-placement="top" title="" data-original-title="tooltip on top">3</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="q-view" [ngClass]="this.showView ? 'active' : ''">
  <div class="overlay" (click)="this.showView = !this.showView"></div>
  <div class="content">
    <div class="card-body">
      <h4>Chrome bug The page uses a roller to slide under a black block<span class="badge badge-danger text-uppercase ml-2 f-12">Private</span></h4>
      <div class="border-bottom pb-3 pt-3">
        <div class="row">
          <div class="col-md-7">
            <button type="button" class="btn btn-outline-success btn-sm"><i class="feather icon-check mr-1"></i> Closed</button>
            <p class="list-inline-item mb-0"><img src="assets/images/ticket/p1.jpg" alt="" class="wid-20 rounded mr-1 img-fluid">Alpha pro</p>
          </div>
          <div class="col-md-5 text-right">
            <p class="d-inline-block mb-0"><i class="feather icon-calendar mr-1"></i><label class="mb-0">Jan,1st,2019</label></p>
          </div>
        </div>
      </div>
    </div>

    <!-- [ tinymce editor ] start -->
    <div class="col-sm-12">
      <div class="card border-0 shadow-none">
        <div class="card-body pr-0 pl-0 pt-0">
          <div class="row">
            <div class="col-auto">
              <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-5.jpg" alt="Generic placeholder image ">
            </div>
            <div class="col">
              <div class="tab-block border-bottom mb-4 pb-3">
                <h5><i class="feather icon-corner-up-left mr-1"></i>Reply</h5>
              </div>
              <p class="text-danger"><span class="font-weight-bold mr-1">Note!</span>This ticket is closed. If you want to re-open it, just post a reply below.</p>
              <app-tinymce [(ngModel)]='basicContent' #tinymce='tinymce'></app-tinymce>
              <div class="btn-block mt-3">
                <div class="btn-group mb-2 mr-2 dropdown" ngbDropdown placement="auto">
                  <button type="button" class="btn btn-primary">Primary</button>
                  <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="sr-only">Toggle Dropdown</span></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:">Action</a>
                    <a class="dropdown-item" href="javascript:">Another action</a>
                    <a class="dropdown-item" href="javascript:">Something else here</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:">Separated link</a>
                  </div>
                </div>
                <div class="file btn btn-outline-secondary file-btn">
                  <i class="feather icon-paperclip"></i>Add Atachment
                  <input type="file" name="file" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-bottom border-top pb-3 pt-4 pl-3 pr-3">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-5.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <h6 class="mb-0">Support Agent name<span class="badge badge-secondary ml-2">Support Agent</span></h6>
            <label class="text-muted">5 Month ago</label>
          </div>
          <div class="col-auto">
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-1"><a href="javascript:"><i class="feather icon-edit text-muted"></i></a></li>
              <li class="d-inline-block"><a href="javascript:"><i class="feather icon-trash-2 text-muted"></i></a></li>
            </ul>
          </div>
          <div class="col-md-12 mt-3">
            <div class="comment-content">
              <p>hello John lui,</p>
              <p>you need to create <strong>"toolbar-options" div only once</strong> in a page in your code, this div fill found
                <strong>every "td"</strong> tag in your page, just remove those things.
              </p>
              <p>and also</p>
              <p>in option button add "<strong>p-0</strong>" class in "<strong>I</strong>" tag</p>
              <p>to</p>
              <p></p>
              <p>Thanks...</p>
            </div>
          </div>
        </div>
        <div class="row border-bottom pb-3 pt-4 pl-3 pr-3">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-4.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <h6 class="mb-0">Support Agent name<span class="badge badge-secondary ml-2">Support Agent</span></h6>
            <label class="text-muted">5 Month ago</label>
          </div>
          <div class="col-auto">
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-1"><a href="javascript:"><i class="feather icon-edit text-muted"></i></a></li>
              <li class="d-inline-block"><a href="javascript:"><i class="feather icon-trash-2 text-muted"></i></a></li>
            </ul>
          </div>
          <div class="col-md-12 mt-3">
            <div class="comment-content">
              <p>hello John lui,</p>
              <p>you need to create <strong>"toolbar-options" div only once</strong> in a page in your code, this div fill found
                <strong>every "td"</strong> tag in your page, just remove those things.
              </p>
              <p>and also</p>
              <p>in option button add "<strong>p-0</strong>" class in "<strong>I</strong>" tag</p>
              <p>to</p>
              <p></p>
              <p>Thanks...</p>
            </div>
            <div class="row text-center mb-2">
              <div class="col-xl-2 col-lg-3 col-sm-4 col-xs-12">
                <a href="assets/images/light-box/l1.jpg" data-toggle="lightbox" data-gallery="example-gallery"><img src="assets/images/light-box/sl1.jpg" class="img-fluid m-b-10" alt=""></a>
              </div>
              <div class="col-xl-2 col-lg-3 col-sm-4 col-xs-12">
                <a href="assets/images/light-box/l1.jpg" data-toggle="lightbox" data-gallery="example-gallery"><img src="assets/images/light-box/sl1.jpg" class="img-fluid m-b-10" alt=""></a>
              </div>
              <div class="col-xl-2 col-lg-3 col-sm-4 col-xs-12">
                <a href="assets/images/light-box/l1.jpg" data-toggle="lightbox" data-gallery="example-gallery"><img src="assets/images/light-box/sl1.jpg" class="img-fluid m-b-10" alt=""></a>
              </div>
              <div class="col-xl-2 col-lg-3 col-sm-4 col-xs-12">
                <a href="assets/images/light-box/l1.jpg" data-toggle="lightbox" data-gallery="example-gallery"><img src="assets/images/light-box/sl1.jpg" class="img-fluid m-b-10" alt=""></a>
              </div>
              <div class="col-xl-2 col-lg-3 col-sm-4 col-xs-12">
                <a href="assets/images/light-box/l1.jpg" data-toggle="lightbox" data-gallery="example-gallery"><img src="assets/images/light-box/sl1.jpg" class="img-fluid m-b-10" alt=""></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-bottom pb-3 pt-4 pl-3 pr-3">
          <div class="col-auto">
            <img class="media-object wid-60 img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image ">
          </div>
          <div class="col">
            <h6 class="mb-0">Support Agent name<span class="badge badge-secondary ml-2">Support Agent</span></h6>
            <label class="text-muted">5 Month ago</label>
          </div>
          <div class="col-auto">
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-1"><a href="javascript:"><i class="feather icon-edit text-muted"></i></a></li>
              <li class="d-inline-block"><a href="javascript:"><i class="feather icon-trash-2 text-muted"></i></a></li>
            </ul>
          </div>
          <div class="col-md-12 mt-3">
            <div class="comment-content">
              <p>hello John lui,</p>
              <p>you need to create <strong>"toolbar-options" div only once</strong> in a page in your code, this div fill found
                <strong>every "td"</strong> tag in your page, just remove those things.
              </p>
              <p>and also</p>
              <p>in option button add "<strong>p-0</strong>" class in "<strong>I</strong>" tag</p>
              <p>to</p>
              <p></p>
              <p>Thanks...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- [ tinymce editor ] end -->
  </div>
</div>
