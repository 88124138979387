<div class="row btn-page">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
      title="{{'EXECUTIVE.DOWNLOAD-STATISTICS-FOR-EACH-STUDENTS-ENTRANCE-EXAM-BY-COURSES' | translate}}"
      style="margin-bottom: 10px;float: right;" (click)="exportexcel('Usage-statistics')">
      <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
    </button>
  </div>
  <div class="col-xl-12 col-lg-12">
    <app-card cardTitle="{{'EXECUTIVE.STATISTICS-FOR-EACH-STUDENTS-ENTRANCE-EXAM-BY-COURSES' | translate}}"
      cardClass="task-board-left" [options]="false">
      <div class="input-group mb-3">
        <ng-select [ngClass]="'ng-select'" [options]="dataStudentAll" [(ngModel)]="selectedStudent"
          *ngIf="selectedStudent != null && selectedStudent != undefined && selectedStudent != ''"
          (ngModelChange)="getDataSubjectStudent($event)">
          <ng-template let-option="dataStudentAll">
            <div class="famfamfam-flags {{dataStudentAll?.value.toLowerCase()}}"></div>
            {{dataStudentAll.label}}
          </ng-template>
        </ng-select>
      </div>
      <div class="task-right" *ngFor="let item of dataSubject;let i = index">
        <div class="task-right-header-status" (click)="onClick(i,item.data.subjectId)">
          <span class="f-w-400">{{item.data.nameTh}}</span>
          <i class="fas fa-caret-down float-right m-t-5"></i>
          <div id="collape" *ngIf="examDataToShowFinal == null" [ngbCollapse]="ind != i"
            class="taskboard-right-progress">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr style="text-align: center;color: red;">
                    *{{'EXECUTIVE.NO-DATA' | translate}}
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div id="collape" *ngIf="examDataToShowFinal != null" [ngbCollapse]="ind != i"
            class="taskboard-right-progress">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr style="text-align: center;">
                    <th>{{'EXECUTIVE.LESSON' | translate}}</th>
                    <th>{{'EXECUTIVE.LESSON-NAME' | translate}}</th>
                    <th style="color: #459fff;">{{'EXECUTIVE.ALL-EXAMS' | translate}}</th>
                    <th style="color:#ff6270">{{'EXECUTIVE.FAILED-THE-TEST' | translate}}</th>
                    <th style="color: #2ed8b6;">{{'EXECUTIVE.PASSED-THE-TEST' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of examDataToShowFinal;let i = index;">
                  <tr style="text-align: center;">
                    <td>{{item.lessonData.sequence}}</td>
                    <td>{{item.lessonData.title}}</td>
                    <td>
                      <span class="badge m-r-5 badge-light-primary">{{item.examCount}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                    <td>
                      <span class="badge m-r-5 badge-light-danger">{{item.notPassCount}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                    <td>
                      <span class="badge m-r-5 badge-light-success">{{item.passCount}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngFor="let item of postTestDataToShow;let i = index;">
                  <tr style="text-align: center;">
                    <td>-</td>
                    <td>{{item.name}}</td>
                    <td>
                      <span class="badge m-r-5 badge-light-primary">{{item.count}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                    <td>
                      <span class="badge m-r-5 badge-light-danger">{{item.failCount}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                    <td>
                      <span class="badge m-r-5 badge-light-success">{{item.passCount}} {{'EXECUTIVE.TIMES' |
                        translate}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="display:flex;justify-content: center" *ngIf="dataSubject == null">
        <p><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
      </div>
    </app-card>
  </div>
</div>