import { Injectable, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationItem } from "src/app/theme/layout/admin/navigation/navigation";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public languages: string[] = ["en", "th"];
  public navigation: any;
  public languageValue: any = localStorage.getItem("lang");


  constructor(public translate: TranslateService,private titleService: Title,private route: Router,public nav: NavigationItem,) {
    let browserLang;
    translate.addLangs(this.languages);

    this.navigation = this.nav.get();

    if (localStorage.getItem("lang")) {
      browserLang = localStorage.getItem("lang");
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|th/) ? browserLang : "en");
    this.checkLanguages();
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
    var title = localStorage.getItem("title");

    this.languageValue = localStorage.getItem("lang");
    
    let routerUrl: string;
    routerUrl = this.route.url;
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }
    // window.location.reload();
  }

  checkLanguages(){
    if (localStorage.getItem("lang") == null || localStorage.getItem("lang") == '') {
      localStorage.setItem("lang", "en");
    }
  }

  filterNavigation(activeLink) {
    let result = [];
    let title = 'Welcome';
    this.navigation.forEach((a) => {
      if (a.type === 'item' && 'url' in a && a.url === activeLink) {
        result = [
          {
            url: 'url' in a ? a.url : false,
            title: a.title,
            breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
            type: a.type,
          },
        ];
        title = a.title;
      } else {
        if (a.type === 'group' && 'children' in a) {
          a.children.forEach((b) => {
            if (b.type === 'item' && 'url' in b && b.url === activeLink) {
              result = [
                {
                  url: 'url' in b ? b.url : false,
                  title: b.title,
                  breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                  type: b.type,
                },
              ];
              title = b.title;
            } else {
              if (b.type === 'collapse' && 'children' in b) {
                b.children.forEach((c) => {
                  if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                    result = [
                      {
                        url: 'url' in b ? b.url : false,
                        title: b.title,
                        breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                        type: b.type,
                      },
                      {
                        url: 'url' in c ? c.url : false,
                        title: c.title,
                        breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                        type: c.type,
                      },
                    ];
                    title = c.title;
                  } else {
                    if (c.type === 'collapse' && 'children' in c) {
                      c.children.forEach((d) => {
                        if (
                          d.type === 'item' &&
                          'url' in d &&
                          d.url === activeLink
                        ) {
                          result = [
                            {
                              url: 'url' in b ? b.url : false,
                              title: b.title,
                              breadcrumbs:
                                'breadcrumbs' in b ? b.breadcrumbs : true,
                              type: b.type,
                            },
                            {
                              url: 'url' in c ? c.url : false,
                              title: c.title,
                              breadcrumbs:
                                'breadcrumbs' in c ? c.breadcrumbs : true,
                              type: c.type,
                            },
                            {
                              url: 'url' in d ? d.url : false,
                              title: d.title,
                              breadcrumbs:
                                'breadcrumbs' in c ? d.breadcrumbs : true,
                              type: d.type,
                            },
                          ];
                          title = d.title;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    });
    this.translate.get(title).subscribe((name) => {
      this.titleService.setTitle(name);
    });
    return result;
  }
}
