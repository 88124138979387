import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdminSharedDataService {
  private dataSource = new BehaviorSubject<any>('');
  getDataAdminLogin = this.dataSource.asObservable();

  private dataSourceMemberStudent = new BehaviorSubject<any>('');
  getDataMemberStudent = this.dataSourceMemberStudent.asObservable();

  private dataSourceMemberTeacher = new BehaviorSubject<any>('');
  getDataMemberTeacher = this.dataSourceMemberTeacher.asObservable();

  private dataSourceMemberExecutive = new BehaviorSubject<any>('');
  getDataMemberExecutive = this.dataSourceMemberExecutive.asObservable();

  private dataSourceMemberAdmin = new BehaviorSubject<any>('');
  getDataMemberAdmin = this.dataSourceMemberAdmin.asObservable();

  private dataSourceProgressUploadFile = new BehaviorSubject<any>(0);
  getdataSourceProgressUploadFile = this.dataSourceProgressUploadFile.asObservable();

  private dataSelectPortal = new BehaviorSubject<any>('');
  getDataelectPortal = this.dataSelectPortal.asObservable();

  constructor() { }


  changeDataAdminLogin(val: any) {
    this.dataSource.next(val);
  }

  changeDataMemberStudent(val: any) {
    this.dataSourceMemberStudent.next(val);
  }

  changeDataMemberTeacher(val: any) {
    this.dataSourceMemberTeacher.next(val);
  }

  changeDataMemberExecutive(val: any) {
    this.dataSourceMemberExecutive.next(val);
  }

  changeDataMemberAdmin(val: any) {
    this.dataSourceMemberAdmin.next(val);
  }

  changeSelectPortal(val: any) {
    this.dataSelectPortal.next(val);
  }

}
