import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { ReportUserRegisterComponent } from '../app/components/reports/report-user-register/report-user-register.component';
import { ReportProblemComponent } from './components/reports/report-problem/report-problem.component';
import { ReportStatusStudentComponent } from './components/reports/report-status-student/report-status-student.component';
import { ReportSatisfactionComponent } from './components/reports/report-satisfaction/report-satisfaction.component';
import { ReportUsageStatisticsComponent } from './components/reports/report-usage-statistics/report-usage-statistics.component';
import { ViewSummaryComponent } from './components/reports/report-satisfaction/view-summary/view-summary.component';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbAccordionModule, NgbButtonsModule, NgbCarouselModule, NgbCollapseModule, NgbDropdownModule, NgbModule, NgbNavModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from './core/services/admin-service/admin.service';
import { UploadFileService } from './core/services/upload-file-service/upload-file.service';
import { StudentService } from './core/services/student-service/student.service';
import { TeacherService } from './core/services/teacher-service/teacher.service';
import { ExecutiveService } from './core/services/executive-service/executive.service';
import { AuthService } from './core/services/auth/auth.service';
import { ServiceManagerService } from './core/services/service-manager.service';
import { AdminSharedDataService } from './core/common/adminSharedDataService';
import { TeaCherSharedDataService } from './core/common/TeaCherSharedDataService';
import { ExecutiveSharedDataService} from './core/common/ExecutiveSharedDataservice';
import { TimeagoModule } from 'ngx-timeago';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AdvNotificationRoutingModule } from './demo/ui-elements/ui-adv/adv-notification/adv-notification-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportCoursesAllUserComponent } from 'src/app/components/reports/report-courses-all-user/report-courses-all-user.component';
import { ReportCoursesComponent } from 'src/app/components/reports/report-courses/report-courses.component';
import { ReportCoursesIncomeComponent } from 'src/app/components/reports/report-courses-income/report-courses-income.component';
import { ReportPreStudyPostStudyTestResultsComponent } from 'src/app/components/reports/report-pre-study-post-study-test-results/report-pre-study-post-study-test-results.component';
import { ReportOfGraduatesCourseComponent } from 'src/app/components/reports/report-of-graduates-course/report-of-graduates-course.component';
import { ReportNumberAttendeesPrintCertificateComponent } from 'src/app/components/reports/report-number-attendees-print-certificate/report-number-attendees-print-certificate.component';
import { DataTablesModule } from 'angular-datatables';
import { ArchwizardModule } from 'angular-archwizard';
import { TinymceModule } from 'angular2-tinymce';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { EmailRoutingModule } from './demo/application/email/email-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SelectModule } from 'ng-select';
import { FrmSelectRoutingModule } from './demo/pages/form-elements/frm-select/frm-select-routing.module';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { TagInputModule } from 'ngx-chips';
import { HdCustomerRoutingModule } from './demo/application/helpdesk/hd-customer/hd-customer-routing.module';
import { LightboxModule } from 'ngx-lightbox';
import { CustomFormsModule } from 'ngx-custom-validators';
import { CrtChartJsRoutingModule } from './demo/pages/core-chart/crt-chart-js/crt-chart-js-routing.module';
import { ChartModule } from 'angular2-chartjs';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ReportAllService } from './core/services/report-all/report-all.service';


const config: SocketIoConfig = { url: 'http://socket.achatsocial.com:2022/', options: { transports: ['websocket', 'polling', 'flashsocket'] } };
//const config: SocketIoConfig = { url: 'http://localhost:2022/', options: {transports: ['websocket', 'polling', 'flashsocket']} }; //local
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    ReportCoursesAllUserComponent,
    ReportCoursesComponent,
    ReportCoursesIncomeComponent,
    ReportUserRegisterComponent,
    ReportProblemComponent,
    ReportStatusStudentComponent,
    ReportSatisfactionComponent,
    ReportUsageStatisticsComponent,
    ViewSummaryComponent,
    ReportPreStudyPostStudyTestResultsComponent,
    ReportOfGraduatesCourseComponent,
    ReportNumberAttendeesPrintCertificateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    AdvNotificationRoutingModule,
    SnotifyModule,
    FormsModule,
    TimeagoModule.forRoot(),
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbNavModule,
    DataTablesModule,
    ArchwizardModule,
    TinymceModule,
    FileUploadModule,
    NgbCarouselModule,
    EmailRoutingModule,
    NgbCollapseModule,
    NgbAccordionModule,
    CKEditorModule,
    SelectModule,
    FrmSelectRoutingModule,
    AngularDualListBoxModule,
    TagInputModule,
    HdCustomerRoutingModule,
    NgbModule,
    LightboxModule,
    CustomFormsModule,
    CrtChartJsRoutingModule,
    ChartModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule, SocketIoModule.forRoot(config),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [NavigationItem,AdminSharedDataService,TeaCherSharedDataService,ExecutiveSharedDataService,AdminService,ServiceManagerService,ExecutiveService,StudentService,TeacherService,AuthService,UploadFileService,{ provide: 'SnotifyToastConfig', useValue: ToastDefaults },SnotifyService,ReportAllService,
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
