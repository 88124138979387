import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';
// import { useState } from "react";
import * as moment from 'moment';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';

@Component({
  selector: 'app-report-user-register',
  templateUrl: './report-user-register.component.html',
  styleUrls: ['./report-user-register.component.scss']
})

export class ReportUserRegisterComponent implements OnInit {
  urlImage = Constants.REST_API_MAIN;
  dataLoading1: boolean = true;
  dataTeacher: any;
  dataStudent: any;
  openTeacher: boolean = true;
  openStudent: boolean = false;
  selectUser = 'ผู้สอน';
  TeacherAndStudent: any;
  Reportuser: any;
  title = 'angular-app';
  startDate: any;
  endDate: any;
  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
  }

  ngOnInit() {
    this.getTeacherAndstudent();
    this.onChangSelectPortal();
  }

  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.getTeacherAndstudent();
      }
    });
  }

  clickOpenTeacherAndStudent() {
    if (this.selectUser == 'ผู้สอน') {
      this.startDate = document.getElementById('start');
      this.endDate = document.getElementById('end');
      this.dataLoading1 = true;
      this.Reportuser = this.TeacherAndStudent.dataTeachers;
      this.openTeacher = true;
      this.openStudent = false;
      // setTimeout(() => {
      this.dataLoading1 = false;
      // }, 1000);
    } else {
      this.startDate = document.getElementById('start');
      this.endDate = document.getElementById('end');
      this.dataLoading1 = true;
      this.Reportuser = this.TeacherAndStudent.dataStudents;
      this.openTeacher = false;
      this.openStudent = true;
      // setTimeout(() => {
      this.dataLoading1 = false;
      // }, 1000);
    }
  }

  clearDateAll() {
    this.startDate = document.getElementById('start');
    this.endDate = document.getElementById('end');
    this.getTeacherAndstudent();
  }

  clearDateEnd() {
    this.endDate = "YYYY-MM-DD";
  }
  searchDate(dateEnd: any) {
    var filterDataDate: any;
    if (this.selectUser == 'ผู้สอน') {
      filterDataDate = this.Reportuser.filter(f => moment(f.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
      this.TeacherAndStudent.dataTeachers = filterDataDate;
    } else {
      filterDataDate = this.Reportuser.filter(f => moment(f.dataStudents.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dataStudents.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
      this.TeacherAndStudent.dataStudents = filterDataDate;
    }

  }

  getTeacherAndstudent() {
    var teacherandstd: any;
    this.adminService.fetchDataAllMemmerUser().then((res) => {
      teacherandstd = res;
      this.TeacherAndStudent = teacherandstd;
      // console.log(this.TeacherAndStudent);

      this.Reportuser = this.TeacherAndStudent.dataTeachers;
      this.dataLoading1 = true;
      setTimeout(() => {
        this.dataLoading1 = false;
      }, 1000);

    })
  }
  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
}
