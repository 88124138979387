import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GradientConfig } from '../../../../app-config';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { forEach } from 'jszip';
import { stringify } from 'querystring';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public gradientConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  dataPortalAll: any = [];
  selectedPortal: any = "";
  showPortalSelect: any = "";
  dataLocal: any;
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
    this.gradientConfig = GradientConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
    var local = localStorage.getItem("currentUserLogin");
    if (local != null) {
      this.dataLocal = JSON.parse(local);
    }
  }

  ngOnInit() {
    this.GetPortal();
  }
  GetPortal() {
    this.adminService.GetPortal().then((res) => {
      if (res.status) {
        var localPortal = localStorage.getItem("select_portal");
        var dataPortalList = [];
        for (let i = 0; i < res.data.length; i++) {
          // if (!res.data[i].portalDisable)
          dataPortalList.push({
            value: res.data[i].portalId.toString(),
            label: res.data[i].portalType1,
          });
        }
        this.dataPortalAll = dataPortalList;
        if (localPortal != null) {
          var dataLocal = JSON.parse(localPortal);
          var dataPortalSelect = this.dataPortalAll.find((x: { value: string; }) => x.value == dataLocal.value);
          if (dataPortalSelect != undefined && dataPortalSelect != null) {
            this.selectedPortal = dataLocal.value;
            this.showPortalSelect = dataLocal.label;
          } else {
            localStorage.setItem("select_portal", JSON.stringify(dataPortalList[0]));
            this.selectedPortal = dataPortalList[0].value;
            this.showPortalSelect = dataPortalList[0].label;
          }

        } else {
          localStorage.setItem("select_portal", JSON.stringify(dataPortalList[0]));
          this.selectedPortal = dataPortalList[0].value;
          this.showPortalSelect = dataPortalList[0].label;
          this.adminSharedDataService.changeSelectPortal(dataPortalList[0]);
        }
      }
    })
  }

  selectProtal(dataPortal: any) {
    var dataPortalSelect = this.dataPortalAll.find((x: { value: string; }) => x.value == dataPortal);
    this.showPortalSelect = dataPortalSelect.label;
    localStorage.setItem("select_portal", JSON.stringify(dataPortalSelect));
    this.adminSharedDataService.changeSelectPortal(dataPortalSelect);
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
