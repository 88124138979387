import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { TeaCherSharedDataService } from 'src/app/core/common/TeaCherSharedDataService';
import { Role } from 'src/app/core/models/role';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TeacherService } from 'src/app/core/services/teacher-service/teacher.service';
import {GradientConfig} from '../../../../app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public windowWidth: number;
  public gradientConfig: any;
  @Output() onNavMobCollapse = new EventEmitter();

  constructor(
    private router: Router,
    private authService: AuthService,
    private teacherService: TeacherService,
    private teaCherSharedDataService: TeaCherSharedDataService,
    private adminSharedDataService: AdminSharedDataService) {
    this.gradientConfig = GradientConfig.config;
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() { 
    this.checkLogin_();
  }

  navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }

  loadDataUserlogin(){
    var local = localStorage.getItem("currentUserLogin");
    if (local != null) {
      var dataLocal = JSON.parse(local);
      if (dataLocal.role == Role.SuperAdmin || dataLocal.role == Role.Admin) {
        this.authService.findDataAdmin(dataLocal.id.toString()).then(response => {
          this.adminSharedDataService.changeDataAdminLogin(response);
        });
      } else if (dataLocal.role == Role.Teacher) {
        this.teacherService.FindDataTeacher(dataLocal.id).then(response => {
          this.teaCherSharedDataService.changeDataTeaCherLogin(response);
          this.getDataSubjectTeacher(response.result.tchId);
        });
      } else if (dataLocal.role == Role.Executive) {

      } else {

      }
    }
  }

  getDataSubjectTeacher(teacherID:string) {
    this.teacherService.GetSubjectByIdTeacher(teacherID).then(res => {
      if(res.status){ 
        this.teaCherSharedDataService.changeDataSubjectAll(res);
      }else{
        this.teaCherSharedDataService.changeDataSubjectAll("");
      }
    },error => {
      console.log("Error",(error));
    });
  }

  checkLogin_() {
    var dataResponse: any;
    this.authService.checkLogin().then(response => {
      // console.log("response : ", response);
      dataResponse = response;
      if (dataResponse.message == "Login-success") {
        this.loadDataUserlogin();
        // var local = localStorage.getItem("currentUserLogin");
        // if (local != null) {
        //   var dataLocal = JSON.parse(local);
        //   if (dataLocal.role == Role.SuperAdmin || dataLocal.role == Role.Admin) {
        //     this.router.navigate([`/admin/dashboard`], { replaceUrl: true });
        //   } else if (dataLocal.role == Role.Teacher) {
        //     this.router.navigate([`/teacher/dashboard`], { replaceUrl: true });
        //   } else if (dataLocal.role == Role.Executive) {
        //     this.router.navigate([`/executive/dashboard`], { replaceUrl: true });
        //   } else {
  
        //   }
        // }
      }

    }, err => {
      console.log("err : ", err);
      // token หมดอายุ
      if (err.statusText === "Unauthorized") {
        var dataLoin = JSON.parse(localStorage.getItem('currentUserLogin'));
        if (dataLoin != null) {
          this.router.navigate([`/auth/errorAnth`], { replaceUrl: true });
        } else {
          this.router.navigate([`/auth/signin`], { replaceUrl: true });
        }
      }

      // เชื่อมต่อ api ไม่ได้
      if (err.statusText === "Unknown Error" || err.statusText === "Not Found") {
        this.router.navigate([`/auth/errorConnectApi`], { replaceUrl: true });
      }

    })
  }

}
