import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AdminService } from '../admin-service/admin.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

constructor(private adminService: AdminService) { }

uploadImg(dataImg) {
  return new Promise<any>((resolve, reject) => {
    const formData = new FormData();
    for (let file of dataImg) {
      formData.append('FormFiles', file);
    }
    this.adminService.uploadFiles(formData).then((response) => {
      
      resolve(response);
      
    }, (error) => {
      console.log("error : ",error);
      if (error.status == 200 && error.ok == false) {
        Swal.fire({
          title: 'บันทึกรูปไม่สำเร็จ',
          text: 'เปลี่ยนรูปภาพหรือไฟล์ใหม่ หรือ เปลี่ยนชื่อแล้วทำการบันทึกใหม่',
          type:'error', 
          confirmButtonText: 'ยืนยัน',
        });
      }
      reject(error);
    });
  });
}

}
