import { Constants } from '../../constants/constant';
import { Injectable } from '@angular/core';
import { ServiceManagerService } from '../service-manager.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

constructor(private serviceManagerService: ServiceManagerService) { }

}
