<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <div class="row">
    <div class="col-sm-7">
      <h5>
        {{'EXECUTIVE.SEARCH-BY-NAME-LIST' | translate}}
      </h5>
    </div>
    <div class="col-sm-5">
      <ng-select [ngClass]="'ng-select'" [options]="dataStudentAll"
        *ngIf="dataStudentAll != '' && dataStudentAll != null && dataStudentAll != undefined"
        [(ngModel)]="selectedStudent" (ngModelChange)="getDataSubjectStudent($event)">
        <ng-template let-option="dataStudentAll">
          <div class="famfamfam-flags {{dataStudentAll?.value.toLowerCase()}}"></div>
          {{dataStudentAll.label}}
        </ng-template>
      </ng-select>
      <p *ngIf="dataStudentAll == '' || dataStudentAll == null || dataStudentAll == undefined"
        style="text-align: center;padding-top: 20px;"><b>{{'EXECUTIVE.NO-CONTACT-INFORMATION' | translate}}</b></p>
    </div>
  </div>
</app-card>
<div class="row">
  <!-- profile start -->
  <div class="col-xl-4 col-12">
    <app-card [customHeader]="true" [options]="false" [isCardFooter]="true">
      <div class="app-card-header" *ngIf="detailStudent != null">
        <div class="row align-items-center">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-auto col pr-0">
                <img class="img-radius img-fluid" style="width: 80px; height:
                  76px;object-fit: cover;" src="{{urlImage}}{{detailStudent.dataStudents.image}}" alt="User
                  image" onerror="this.src='assets/images/no-img.png'">
              </div>
              <div class="col">
                <h6 class="mb-1">{{detailStudent.dataStudents.fullNameTh}}
                  ({{detailStudent.dataStudents.fullNameEn}})</h6>
                <p class="mb-0" *ngIf="detailStudent.dataStudents.studentType == true">{{'EXECUTIVE.GENERAL-PERSON'
                  |
                  translate}}</p>
                <p class="mb-0" *ngIf="detailStudent.dataStudents.studentType == false">{{'EXECUTIVE.STUDENT'
                  |
                  translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center" *ngIf="detailStudent != null">
        <div class="col">
          <h6 class="mb-1"><i class="icon feather icon-user f-16 text-c-blue" style="padding-right: 10px;"></i></h6>
          <p class="mb-0">{{detailStudent.dataStudents.username}}</p>
        </div>
        <!-- <div class="col">
          <h6 class="mb-1"><i class="icon feather icon-calendar f-16 text-c-blue" style="padding-right: 10px;"></i></h6>
          <p class="mb-0">{{detailStudent.brithOfDate | date:'mediumDate'}}</p>
        </div> -->
        <div class="col">
          <h6 class="mb-1"><i class="icon feather icon-users f-16 text-c-blue" style="padding-left: 2px;"></i></h6>
          <p class="mb-0" *ngIf="detailStudent.dataStudents.sex == 'M'">{{'EXECUTIVE.MALE'
            | translate}}</p>
          <p class="mb-0" *ngIf="detailStudent.dataStudents.sex == 'F'">{{'EXECUTIVE.FEMALE'
            | translate}}</p>
        </div>
        <!-- <div class="col">
          <h6 class="mb-1"><i class="icon feather icon-phone f-16 text-c-blue" style="padding-right: 10px;"></i></h6>
          <p class="mb-0">{{detailStudent.phoneNumber}}</p>
        </div> -->
      </div>
      <div class="app-card-footer" *ngIf="detailStudent != null">
        <perfect-scrollbar class="scrollbarUser"
          [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
          <!-- <div class="row align-items-center" style="padding-top: 20px; 
          padding-bottom: 14px;">
          <div class="col"> -->
          <p><i class="icon feather icon-credit-card f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.STUDENT-ID' |
              translate}} :
            </b>{{detailStudent.dataStudents.stdntId}}</p>
          <p><i class="icon feather icon-credit-card f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.ID-CARD' |
              translate}}
              :</b>{{detailStudent.dataStudents.citizenId}}</p>
          <p><i class="icon feather icon-mail f-16 text-c-blue" style="padding-right: 10px;"></i><b>{{'EXECUTIVE.E-MAIL'
              | translate}}
              :</b>{{detailStudent.dataStudents.email}}</p>
          <p><i class="icon feather icon-calendar f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.DATE-OF-BIRTH' |
              translate}}
              :</b>{{detailStudent.dataStudents.brithOfDate
            | date:'mediumDate'}}</p>
          <p><i class="icon feather icon-phone f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.PHONE-NUMBER' |
              translate}}
              :</b>
            *******{{detailStudent.dataStudents.phoneNumber[7]}}{{detailStudent.dataStudents.phoneNumber[8]}}{{detailStudent.dataStudents.phoneNumber[9]}}
          </p>
          <p><i class="icon feather icon-home f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.CURRENT-ADDRESS'
              | translate}} :
            </b>{{address[0]}},{{address[3]}},
            {{address[2]}},{{address[1]}}</p>
          <p><i class="icon feather icon-home f-16 text-c-blue"
              style="padding-right: 10px;"></i><b>{{'EXECUTIVE.ZIP-CODE' |
              translate}} :
            </b>{{address[4]}}</p>
          <!-- </div>
        </div> -->
        </perfect-scrollbar>
      </div>
      <p *ngIf="detailStudent == null" style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
          |
          translate}}</b></p>
    </app-card>
  </div>
  <!-- profile end -->

  <!-- course start -->
  <div class="col-xl-8 col-12">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
      <div style="padding-top: 20px;padding-left: 10px;justify-content: space-between;display: flex;">
        <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.LEARNER-REPORT-FOLLOWING-THE-COURSES'
            |
            translate}}</b></p>
        <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
          title="{{'EXECUTIVE.DOWNLOAD-LEARNER-REPORT-FOLLOWING-THE-COURSES' | translate}}"
          style="float: right;margin-right: 10px;" (click)="exportexcel('Learner-report-following-the-courses')">
          <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
        </button>
      </div>
      <div *ngIf="CourseSubject != null">
        <div class="table-responsive" *ngIf="CourseSubject.message == 'Success'">
          <perfect-scrollbar class="scrollbarXY"
            [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}"
            *ngIf="CourseSubject != null && CourseSubject != '' && CourseSubject != undefined">
            <br>
            <table class="table table-hover mb-0" *ngIf="!dataLoading3">
              <tbody *ngFor="let item of CourseSubject.data;index as i">
                <tr>
                  <td class="lineUp">{{i+1}}</td>
                  <td>
                    <h6 class="mb-1 lineUp">{{item.dataBuyCourse.dataSubject.nameTh}}</h6>
                    <!-- <p class="m-0" [innerHTML]="std.dataSubject.about"></p> -->
                  </td>
                  <td>
                    <p class="m-b-0 lineUp">{{item.countStudentBuy}} {{'EXECUTIVE.PERSON'
                      | translate}} <i class="fas fa-users text-c-blue m-l-10"></i>
                    </p>
                  </td>
                  <td>
                    <a *ngIf="item.dataBuyCourse.dataSubject.status == 'notFree'"
                      class="badge badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.PAY-COURSES'
                      | translate}}</a>
                    <a *ngIf="item.dataBuyCourse.dataSubject.status == 'free'"
                      class="badge badge-light-success badge-pill f-12 mr-2">{{'EXECUTIVE.FREE-COURSES'
                      |
                      translate}}</a>
                    <a *ngIf="item.dataBuyCourse.dataSubject.ticketType == true"
                      class="badge badge-light-primary badge-pill f-12 mr-2">{{'EXECUTIVE.TICKET'
                      | translate}}</a>
                  </td>
                  <td>
                    <p class="m-b-0 lineUp">{{item.dataBuyCourse.dataSubject.price
                      | number}} {{'EXECUTIVE.BAHT' | translate}} </p>
                  </td>
                  <td>
                    <p class="m-b-0 lineUp"><b>{{'EXECUTIVE.INSTRUCTOR' |
                        translate}}</b>
                      {{item.dataBuyCourse.dataSubject.dataTeacher.fullNameTh}}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;margin: 20px;">
              <div class="spinner-border text-info" role="status" *ngIf="dataLoading3">
                <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div *ngIf="CourseSubject.message != 'Success'">
          <h6 style="text-align: center;">{{'EXECUTIVE.NO-DATA' | translate}}</h6>
        </div>
      </div>
      <p *ngIf="CourseSubject == null" style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
          |
          translate}}</b></p>
    </app-card>
  </div>
  <!-- course end -->

  <!-- study status start -->
  <div class="col-12">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
      <div
        style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;justify-content: space-between;display: flex;">
        <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.STUDY-STATUS'
            | translate}}</b></p>
        <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
          title="{{'EXECUTIVE.DOWNLOAD-STUDY-STATUS' | translate}}" style="float: right;margin-right: 10px;"
          (click)="exportexcel('Study-status')">
          <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
        </button>
      </div>
      <div class="row">
        <div class="col-xl-6 col-12">
          <strong style="padding-left: 24px;">{{'EXECUTIVE.COMEPLETE-COURSES' |
            translate}}</strong>
          <div class="table-responsive"
            *ngIf="studentBuyCouses != null && studentBuyCouses != '' && studentBuyCouses != undefined">
            <perfect-scrollbar style="background-color: white; border-radius: 5px; height: 439px; top: 0px;">
              <table class="table table-hover mb-0" *ngIf="!dataLoading1">
                <tbody>
                  <tr *ngFor="let std of studentBuyCouses;index as i">
                    <td>{{i+1}}</td>
                    <td>
                      <h6 class="mb-1">{{std.dataSubject.nameTh}}</h6>
                      <!-- <p class="m-0" [innerHTML]="std.dataSubject.about"></p> -->
                    </td>
                    <td>
                      <div class="row" style="justify-content: center;display: block;">
                        <div class="col">
                          <div class="mt-1">
                            <ngb-progressbar type="success" height="4px" [value]="100"></ngb-progressbar>
                          </div>
                        </div>
                        <div class="col" style="margin-top: 5px;">
                          <p class="m-b-0">100% ({{'EXECUTIVE.SUCCEED' |
                            translate}})</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="text-align: center;margin: 20px;">
                <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                  <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <p *ngIf="studentBuyCouses == null || studentBuyCouses == '' || studentBuyCouses == undefined"
            style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
              | translate}}</b></p>
        </div>
        <div class="col-xl-6 col-12">
          <strong style="padding-left: 24px;">{{'EXECUTIVE.NOT-COMPLETE-COURSES'
            | translate}}</strong>
          <div class="table-responsive" *ngIf="studentBuyCouses2 != null &&
            studentBuyCouses2 != '' && studentBuyCouses2 != undefined">
            <perfect-scrollbar style="background-color: white; border-radius: 5px; height: 439px; top: 0px;">
              <table class="table table-hover mb-0" *ngIf="!dataLoading1">
                <tbody>
                  <tr *ngFor="let std of studentBuyCouses2;index as i">
                    <td>{{i+1}}</td>
                    <td>
                      <h6 class="mb-1">{{std.dataSubject.nameTh}}</h6>
                      <!-- <p class="m-0">Lorem Ipsum is simply dummy text of…</p> -->
                    </td>
                    <td>
                      <div class="row" style="justify-content: center;display:
                        block;">
                        <div class="col">
                          <div class="mt-1">
                            <ngb-progressbar type="danger" height="4px" [value]="(std.studentlearnWorst)">
                            </ngb-progressbar>
                          </div>
                        </div>
                        <div class="col" style="margin-top: 5px;">
                          <p class="m-b-0">{{std.studentlearnWorst.toFixed([2])}}%
                            ({{'EXECUTIVE.Unsuccessful' | translate}})</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="text-align: center;margin: 20px;">
                <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                  <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <p *ngIf="studentBuyCouses2 == null || studentBuyCouses2 == '' || studentBuyCouses2 == undefined"
            style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
              | translate}}</b></p>
        </div>
      </div>
    </app-card>

  </div>
  <!-- study status end -->

  <!-- academic results start -->
  <div class="col-12" *ngIf="CourseSubject != undefined">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card"
      *ngIf="CourseSubject.message == 'Success'">
      <div class="row">
        <div class="col-md-5" style="padding-top: 20px;padding-bottom: 10px;padding-left: 15px;">
          <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.TEST-RESULT'
              | translate}}</b></p>
        </div>
        <div class="col-md-5 text-right" style="margin-top: 20px;">
          <div class="form-group" style="margin-right: 15px;justify-content: space-between;display: flex;"
            *ngIf="CourseSubject != null && CourseSubject != '' && CourseSubject != undefined">
            <select title="Customer" name="customer" class="mb-3 form-control" [(ngModel)]="dataExam"
              (ngModelChange)="checkExam($event)">
              <option *ngFor="let item of CourseSubject.data" value="{{item.dataBuyCourse.dataSubject.subjectId}}">
                {{item.dataBuyCourse.dataSubject.nameTh}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-2 text-right" style="margin-top: 20px;margin-left: -15px;">
          <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
            title="{{'EXECUTIVE.DOWNLOAD-TEST-RESULT' | translate}}" style="margin-left: 10px;"
            (click)="exportexcel('test-result')">
            <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
          </button>
        </div>
      </div>

      <div *ngIf="dataSubJ != null">
        <div class="row" *ngIf="dataSubJ.dataPreTest != null">
          <div class="col-xl-4 col-md-6 col-sm-12">
            <strong style="padding-left: 24px;">{{'EXECUTIVE.TEST-RESULT-BEFORE-STUDY'
              | translate}}</strong>
            <div class="table-responsive">
              <perfect-scrollbar class="scrollbarXY"
                [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
                <table class="table table-hover mb-0" *ngIf="dataSubJ != null">
                  <tbody>
                    <tr *ngIf="dataSubJ.dataPreTest">
                      <td>1</td>
                      <td>
                        <h6 class="mb-1">{{dataSubJ.dataPreTest.dataSubject.nameTh}}</h6>
                        <!-- <p class="m-0" [innerHTML]="std.dataSubject.about"></p> -->
                      </td>
                      <td>
                        <div class="row" style="justify-content: center;display: block;">
                          <div class="col" style="margin-top: 5px;">
                            <a class="badge badge-light-success badge-pill f-12 mr-2"
                              *ngIf="dataSubJ.dataPreTest.statusResult == 'successful'">{{'EXECUTIVE.PASSED-THE-TEST'
                              |
                              translate}}</a>
                            <a class="badge badge-light-danger badge-pill f-12 mr-2"
                              *ngIf="dataSubJ.dataPreTest.statusResult == 'failed'">{{'EXECUTIVE.FAILED-THE-TEST'
                              |
                              translate}}</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <img *ngIf="dataLoading2" class="text-center d-block border-top
                py-1" src="assets/images/loading/loading_3.gif"
                style="width:220px;margin:
                auto;" alt="loading"> -->
              </perfect-scrollbar>
            </div>
            <!-- <div *ngIf="!dataLoading2">
            <h6 *ngIf="studentBuyCouses == ''" style="text-align: center;">ไม่มีข้อมูล</h6>
          </div> -->
          </div>
          <div class="col-xl-4 col-md-6 col-sm-12">
            <strong style="padding-left: 24px;">{{'EXECUTIVE.TEST-RESULT-BETWEEN-STUDY'
              | translate}}</strong>
            <div class="table-responsive">
              <perfect-scrollbar class="scrollbarXY"
                [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
                <table class="table table-hover mb-0" *ngIf="datatestLesson != null && datatestLesson != []">
                  <tbody>
                    <tr *ngFor="let item of datatestLesson.dataSubject.datalesson;index as i">
                      <td>{{i+1}}</td>
                      <td>
                        <h6 class="mb-1">{{item.title}}</h6>
                        <!-- <p class="m-0">Lorem Ipsum is simply dummy text of…</p> -->
                      </td>
                      <td>
                        <div class="row" style="justify-content: center;display: block;">
                          <div class="col" style="margin-top: 5px;">
                            <a *ngIf="datatestLesson.testResultExamResults == 'successful'"
                              class="badge badge-light-success badge-pill f-12 mr-2">{{'EXECUTIVE.PASSED-THE-TEST'
                              |
                              translate}}</a>
                            <a *ngIf="datatestLesson.testResultExamResults != 'successful'"
                              class="badge badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.FAILED-THE-TEST'
                              |
                              translate}}</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
            </div>
          </div>
          <div class="col-xl-4 col-md-12 col-sm-12">
            <strong style="padding-left: 24px;">{{'EXECUTIVE.TEST-RESULT-AFTER-STUDY'
              | translate}}</strong>
            <div class="table-responsive">
              <perfect-scrollbar class="scrollbarXY"
                [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
                <table class="table table-hover mb-0" *ngIf="dataPostTests != null && dataPostTests != []">
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <h6 class="mb-1">{{dataPostTests.dataSubject.nameTh}}</h6>
                        <!-- <p class="m-0">Lorem Ipsum is simply dummy text of…</p> -->
                      </td>
                      <td>
                        <div class="row" style="justify-content: center;display:
                        block;">
                          <div class="col" style="margin-top: 5px;">
                            <a class="badge badge-light-success badge-pill f-12 mr-2"
                              *ngIf="dataPostTests.statusResult == 'successful'">{{'EXECUTIVE.PASSED-THE-TEST'
                              |
                              translate}}</a>
                            <a class="badge badge-light-warning badge-pill f-12 mr-2"
                              *ngIf="dataPostTests.statusResult == 'waitAnswer'">{{'EXECUTIVE.WAITING-FOR-EXAM'
                              |
                              translate}}</a>
                            <a class="badge badge-light-danger badge-pill f-12 mr-2"
                              *ngIf="dataPostTests.statusResult == 'failed'">{{'EXECUTIVE.FAILED-THE-TEST'
                              |
                              translate}}</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <img *ngIf="dataLoading2" class="text-center d-block border-top
                py-1" src="assets/images/loading/loading_3.gif"
                style="width:220px;margin:
                auto;" alt="loading"> -->
              </perfect-scrollbar>
            </div>
            <!-- <div *ngIf="!dataLoading2">
            <h6 *ngIf="studentBuyCouses == ''" style="text-align: center;">ไม่มีข้อมูล</h6>
          </div> -->
          </div>
        </div>

        <div *ngIf="dataSubJ.dataLesson == '' && dataSubJ.dataPostTests == '' && dataSubJ.dataPreTest == null">
          <h6 style="text-align: center;margin-top: 20px;">{{'EXECUTIVE.NO-DATA'
            | translate}}</h6>
        </div>
      </div>
    </app-card>
  </div>
  <!-- academic results end -->

  <!-- comment start Executive-->
  <div class="col-xl-6 col-12" *ngIf="localRole == 'Executive'">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
      <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;">
        <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.STUDENT-OPINION'
            | translate}}
          </b></p>
      </div>
      <perfect-scrollbar [perfectScrollbar] class="scrollbarXY"
        [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
        <div class="table-responsive">
          <div class="review-block" *ngIf="CommentRating != null">
            <div class="row" *ngFor="let item of CommentRating;">
              <div class="col">
                <div class="row">
                  <div class="col-xl-8" *ngIf="CommentRating != null">
                    <h6 class="m-b-10">{{item.dataComments[0].dataSubject.nameTh}}</h6>
                    <p class="m-t-10 m-b-0"><i class="icon feather icon-clock f-16 text-c-black"
                        style="padding-right: 10px;"></i>{{item.dataComments[0].date
                      | date:'short'}}</p>
                    <p class="m-t-10 m-b-0"><i class="icon feather icon-message-square f-16 text-c-black"
                        style="padding-right: 10px;"></i>{{item.dataComments[0].comment}}</p>
                  </div>
                  <div class="col-xl-4" *ngIf="CommentRating != null">
                    <div class="row align-items-center" style="float: right;">
                      <div class="col">
                        <td>
                          <i
                            [class]="(item.dataComments[0].scrore == 0) ? 'far fa-star text-c-yellow' : (item.dataComments[0].scrore > 0 && item.dataComments[0].scrore < 1) ? 'fas fa-star-half-alt  text-c-yellow' : 'fas fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 1 && item.dataComments[0].scrore < 2) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 2) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 2 && item.dataComments[0].scrore < 3) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 3) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 3 && item.dataComments[0].scrore < 4) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 4) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 4 && item.dataComments[0].scrore < 5) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 5) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                        </td>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <p *ngIf="CommentRating == null || CommentRating == '' || CommentRating == undefined"
          style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
            | translate}}</b></p>
      </perfect-scrollbar>
    </app-card>
  </div>
  <!-- comment end Executive-->

  <!-- comment start Executive-->
  <div class="col-12" *ngIf="localRole == 'Teacher' || localRole == 'SuperAdmin' || localRole == 'Admin'">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
      <div
        style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;justify-content: space-between;display: flex;">
        <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.STUDENT-OPINION'
            | translate}}
          </b></p>
        <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom"
          title="{{'EXECUTIVE.DOWNLOAD-STUDENT-OPINION' | translate}}" style="margin-right: 10px;"
          (click)="exportexcel('student-opinion')">
          <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
        </button>
      </div>
      <perfect-scrollbar [perfectScrollbar] class="scrollbarXY"
        [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
        <div class="table-responsive">
          <div class="review-block" *ngIf="CommentRating != null">
            <div class="row" *ngFor="let item of CommentRating;">
              <div class="col">
                <div class="row">
                  <div class="col-xl-8" *ngIf="CommentRating != null">
                    <h6 class="m-b-10">{{item.dataComments[0].dataSubject.nameTh}}</h6>
                    <p class="m-t-10 m-b-0"><i class="icon feather icon-clock f-16 text-c-black"
                        style="padding-right: 10px;"></i>{{item.dataComments[0].date
                      | date:'short'}}</p>
                    <p class="m-t-10 m-b-0"><i class="icon feather icon-message-square f-16 text-c-black"
                        style="padding-right: 10px;"></i>{{item.dataComments[0].comment}}</p>
                  </div>
                  <div class="col-xl-4" *ngIf="CommentRating != null">
                    <div class="row align-items-center" style="float: right;">
                      <div class="col">
                        <td>
                          <i
                            [class]="(item.dataComments[0].scrore == 0) ? 'far fa-star text-c-yellow' : (item.dataComments[0].scrore > 0 && item.dataComments[0].scrore < 1) ? 'fas fa-star-half-alt  text-c-yellow' : 'fas fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 1 && item.dataComments[0].scrore < 2) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 2) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 2 && item.dataComments[0].scrore < 3) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 3) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 3 && item.dataComments[0].scrore < 4) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 4) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(item.dataComments[0].scrore > 4 && item.dataComments[0].scrore < 5) ? 'fas fa-star-half-alt  text-c-yellow' : (item.dataComments[0].scrore >= 5) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                        </td>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <p *ngIf="CommentRating == null || CommentRating == '' || CommentRating == undefined"
          style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
            | translate}}</b></p>
      </perfect-scrollbar>
    </app-card>
  </div>
  <!-- comment end Executive-->

  <!-- Certificate start -->
  <div class="col-xl-6 col-12" *ngIf="localRole == 'Executive'">
    <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
      <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;">
        <p style="font-size: 18px; padding-left: 14px;"><b>{{'EXECUTIVE.ALL-CERTIFICATE'
            | translate}} </b></p>
      </div>
      <div class="table-responsive">
        <perfect-scrollbar class="scrollbarXY"
          [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
          <table class="table table-hover mb-0">
            <tbody *ngFor="let item of Certificate;index as i">
              <tr>
                <td>{{i+1}}</td>
                <td>
                  <h6 class="mb-1">{{item.datasubject.name_th}}</h6>
                </td>
                <td>
                  <h6 class="text-muted"><i
                      class="fas fa-circle text-c-green f-10 m-r-15"></i>{{item.datasubject.date_create
                    | date:'short'}}
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="Certificate == null || Certificate == '' || Certificate == undefined"
            style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA'
              | translate}}</b></p>
        </perfect-scrollbar>
      </div>
    </app-card>
  </div>
  <!-- Certificate end -->
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Learner-report-following-the-courses"
    style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.STUDENT-ID' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="detailStudent != null">
        <td>{{detailStudent.dataStudents.fullNameTh}}</td>
        <td>{{detailStudent.dataStudents.fullNameEn}}</td>
        <td>{{detailStudent.dataStudents.username}}</td>
        <td>{{detailStudent.dataStudents.stdntId}}</td>
        <td>
          {{detailStudent.dataStudents.citizenId[0]}}-{{detailStudent.dataStudents.citizenId[1]}}{{detailStudent.dataStudents.citizenId[2]}}{{detailStudent.dataStudents.citizenId[3]}}{{detailStudent.dataStudents.citizenId[4]}}-{{detailStudent.dataStudents.citizenId[5]}}{{detailStudent.dataStudents.citizenId[6]}}{{detailStudent.dataStudents.citizenId[7]}}{{detailStudent.dataStudents.citizenId[8]}}{{detailStudent.dataStudents.citizenId[9]}}-{{detailStudent.dataStudents.citizenId[10]}}{{detailStudent.dataStudents.citizenId[11]}}-{{detailStudent.dataStudents.citizenId[12]}}
        </td>
        <td>{{detailStudent.dataStudents.email}}</td>
        <td>
          {{detailStudent.dataStudents.phoneNumber[0]}}{{detailStudent.dataStudents.phoneNumber[1]}}{{detailStudent.dataStudents.phoneNumber[2]}}-{{detailStudent.dataStudents.phoneNumber[3]}}{{detailStudent.dataStudents.phoneNumber[4]}}{{detailStudent.dataStudents.phoneNumber[5]}}-{{detailStudent.dataStudents.phoneNumber[6]}}{{detailStudent.dataStudents.phoneNumber[7]}}{{detailStudent.dataStudents.phoneNumber[8]}}{{detailStudent.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
      </tr>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.LEARNER-REPORT-FOLLOWING-THE-COURSES' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.COURSE.NAME-OF-COURSES' | translate}}</th>
        <th>{{'TEACHER.AMOUNT-OF-STUDENT' | translate}}</th>
        <th>{{'EXECUTIVE.COURSE-STATUS' | translate}}</th>
        <th>{{'EXECUTIVE.USE-TICKET' | translate}}</th>
        <th>{{'EXECUTIVE.COURSE-PRICE' | translate}}</th>
        <th>{{'EXECUTIVE.INSTRUCTOR' | translate}}</th>
      </tr>
    </thead>
    <div *ngIf="CourseSubject != undefined">
      <tbody *ngFor="let item of CourseSubject.data">
        <tr>
          <td> - {{item.dataBuyCourse.dataSubject.nameTh}}</td>
          <td>{{item.countStudentBuy}} {{'EXECUTIVE.PERSON' | translate}}</td>
          <td>
            <p *ngIf="item.dataBuyCourse.dataSubject.status == 'notFree'">{{'EXECUTIVE.PAY-COURSES'
              | translate}}</p>
            <p *ngIf="item.dataBuyCourse.dataSubject.status == 'free'">{{'EXECUTIVE.FREE-COURSES'
              | translate}}</p>
          </td>
          <td *ngIf="item.dataBuyCourse.dataSubject.ticketType == true">{{'EXECUTIVE.TICKET'
            | translate}}</td>
          <td *ngIf="item.dataBuyCourse.dataSubject.ticketType != true"> - </td>
          <td>{{item.dataBuyCourse.dataSubject.price | number}} {{'EXECUTIVE.BAHT'
            | translate}} </td>
          <td>{{item.dataBuyCourse.dataSubject.dataTeacher.fullNameTh}}</td>
        </tr>
      </tbody>
    </div>

  </table>

  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Study-status" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.STUDENT-ID' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="detailStudent != null">
        <td>{{detailStudent.dataStudents.fullNameTh}}</td>
        <td>{{detailStudent.dataStudents.fullNameEn}}</td>
        <td>{{detailStudent.dataStudents.username}}</td>
        <td>{{detailStudent.dataStudents.stdntId}}</td>
        <td>
          {{detailStudent.dataStudents.citizenId[0]}}-{{detailStudent.dataStudents.citizenId[1]}}{{detailStudent.dataStudents.citizenId[2]}}{{detailStudent.dataStudents.citizenId[3]}}{{detailStudent.dataStudents.citizenId[4]}}-{{detailStudent.dataStudents.citizenId[5]}}{{detailStudent.dataStudents.citizenId[6]}}{{detailStudent.dataStudents.citizenId[7]}}{{detailStudent.dataStudents.citizenId[8]}}{{detailStudent.dataStudents.citizenId[9]}}-{{detailStudent.dataStudents.citizenId[10]}}{{detailStudent.dataStudents.citizenId[11]}}-{{detailStudent.dataStudents.citizenId[12]}}
        </td>
        <td>{{detailStudent.dataStudents.email}}</td>
        <td>
          {{detailStudent.dataStudents.phoneNumber[0]}}{{detailStudent.dataStudents.phoneNumber[1]}}{{detailStudent.dataStudents.phoneNumber[2]}}-{{detailStudent.dataStudents.phoneNumber[3]}}{{detailStudent.dataStudents.phoneNumber[4]}}{{detailStudent.dataStudents.phoneNumber[5]}}-{{detailStudent.dataStudents.phoneNumber[6]}}{{detailStudent.dataStudents.phoneNumber[7]}}{{detailStudent.dataStudents.phoneNumber[8]}}{{detailStudent.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
      </tr>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.STUDY-STATUS' | translate}} ({{'EXECUTIVE.COMEPLETE-COURSES'
          | translate}})</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let std of studentBuyCouses;index as i">
        <td> - {{std.dataSubject.nameTh}}</td>
      </tr>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.STUDY-STATUS' | translate}} ({{'EXECUTIVE.NOT-COMPLETE-COURSES'
          | translate}})</th>
        <th>{{'EXECUTIVE.LEARNING-PROGRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let std of studentBuyCouses2;index as i">
        <td> - {{std.dataSubject.nameTh}}</td>
        <td>{{'EXECUTIVE.SUCCEED' | translate}} {{std.studentlearnWorst.toFixed([2])}} %</td>
      </tr>
    </tbody>
  </table>

  <table witdh="100%" border="1" class="table" #table11 id="excel-table-test-result" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.STUDENT-ID' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="detailStudent != null">
        <td>{{detailStudent.dataStudents.fullNameTh}}</td>
        <td>{{detailStudent.dataStudents.fullNameEn}}</td>
        <td>{{detailStudent.dataStudents.username}}</td>
        <td>{{detailStudent.dataStudents.stdntId}}</td>
        <td>
          {{detailStudent.dataStudents.citizenId[0]}}-{{detailStudent.dataStudents.citizenId[1]}}{{detailStudent.dataStudents.citizenId[2]}}{{detailStudent.dataStudents.citizenId[3]}}{{detailStudent.dataStudents.citizenId[4]}}-{{detailStudent.dataStudents.citizenId[5]}}{{detailStudent.dataStudents.citizenId[6]}}{{detailStudent.dataStudents.citizenId[7]}}{{detailStudent.dataStudents.citizenId[8]}}{{detailStudent.dataStudents.citizenId[9]}}-{{detailStudent.dataStudents.citizenId[10]}}{{detailStudent.dataStudents.citizenId[11]}}-{{detailStudent.dataStudents.citizenId[12]}}
        </td>
        <td>{{detailStudent.dataStudents.email}}</td>
        <td>
          {{detailStudent.dataStudents.phoneNumber[0]}}{{detailStudent.dataStudents.phoneNumber[1]}}{{detailStudent.dataStudents.phoneNumber[2]}}-{{detailStudent.dataStudents.phoneNumber[3]}}{{detailStudent.dataStudents.phoneNumber[4]}}{{detailStudent.dataStudents.phoneNumber[5]}}-{{detailStudent.dataStudents.phoneNumber[6]}}{{detailStudent.dataStudents.phoneNumber[7]}}{{detailStudent.dataStudents.phoneNumber[8]}}{{detailStudent.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
      </tr>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <div *ngIf="CourseSubject != undefined">
      <thead *ngFor="let item of CourseSubject.data">
        <tr *ngIf="item.dataBuyCourse.dataSubject.subjectId == dataExam">
          <th>{{'EXECUTIVE.TEST-RESULT' | translate}} {{'EXECUTIVE.SUBJECT' | translate}} : </th>
          <th>{{dataExam}} ( {{item.dataBuyCourse.dataSubject.nameTh}} )</th>
        </tr>
      </thead>
    </div>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.TEST-RESULT-BEFORE-STUDY' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="dataSubJ != null">
        <div *ngIf="dataSubJ.dataPreTest!=null">
          <td>{{dataSubJ.dataPreTest.dataSubject.nameTh}}</td>
          <td *ngIf="dataSubJ.dataPreTest.statusResult == 'successful'">{{'EXECUTIVE.PASSED-THE-TEST'
            | translate}}</td>
          <td *ngIf="dataSubJ.dataPreTest.statusResult == 'failed'">{{'EXECUTIVE.FAILED-THE-TEST'
            | translate}}</td>
        </div>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.TEST-RESULT-BETWEEN-STUDY' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="datatestLesson != null">
      <tr *ngFor="let item of datatestLesson.dataSubject.datalesson;index as i">
        <td>{{item.title}}</td>
        <td *ngIf="datatestLesson.testResultExamResults == 'successful'">{{'EXECUTIVE.PASSED-THE-TEST'
          | translate}}
        </td>
        <td *ngIf="datatestLesson.testResultExamResults != 'successful'">{{'EXECUTIVE.FAILED-THE-TEST'
          | translate}}
        </td>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.TEST-RESULT-AFTER-STUDY' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="dataPostTests != null">
        <td>{{dataPostTests.dataSubject.nameTh}}</td>
        <td *ngIf="dataPostTests.statusResult == 'successful'">{{'EXECUTIVE.PASSED-THE-TEST'
          | translate}}</td>
        <td *ngIf="dataPostTests.statusResult == 'waitAnswer'">{{'EXECUTIVE.WAITING-FOR-EXAM'
          | translate}}</td>
        <td *ngIf="dataPostTests.statusResult == 'failed'">{{'EXECUTIVE.FAILED-THE-TEST'
          | translate}}</td>
    </tbody>
  </table>
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-student-opinion" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.STUDENT-ID' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="detailStudent != null">
        <td>{{detailStudent.dataStudents.fullNameTh}}</td>
        <td>{{detailStudent.dataStudents.fullNameEn}}</td>
        <td>{{detailStudent.dataStudents.username}}</td>
        <td>{{detailStudent.dataStudents.stdntId}}</td>
        <td>
          {{detailStudent.dataStudents.citizenId[0]}}-{{detailStudent.dataStudents.citizenId[1]}}{{detailStudent.dataStudents.citizenId[2]}}{{detailStudent.dataStudents.citizenId[3]}}{{detailStudent.dataStudents.citizenId[4]}}-{{detailStudent.dataStudents.citizenId[5]}}{{detailStudent.dataStudents.citizenId[6]}}{{detailStudent.dataStudents.citizenId[7]}}{{detailStudent.dataStudents.citizenId[8]}}{{detailStudent.dataStudents.citizenId[9]}}-{{detailStudent.dataStudents.citizenId[10]}}{{detailStudent.dataStudents.citizenId[11]}}-{{detailStudent.dataStudents.citizenId[12]}}
        </td>
        <td>{{detailStudent.dataStudents.email}}</td>
        <td>
          {{detailStudent.dataStudents.phoneNumber[0]}}{{detailStudent.dataStudents.phoneNumber[1]}}{{detailStudent.dataStudents.phoneNumber[2]}}-{{detailStudent.dataStudents.phoneNumber[3]}}{{detailStudent.dataStudents.phoneNumber[4]}}{{detailStudent.dataStudents.phoneNumber[5]}}-{{detailStudent.dataStudents.phoneNumber[6]}}{{detailStudent.dataStudents.phoneNumber[7]}}{{detailStudent.dataStudents.phoneNumber[8]}}{{detailStudent.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
      </tr>
    </tbody>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.STUDENT-OPINION' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.SUBJECT' | translate}}</th>
        <th>{{'EXECUTIVE.STUDENT-OPINION' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-TIME' | translate}}</th>
        <th>{{'EXECUTIVE.SCORE' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of CommentRating;">
        <td>{{item.dataComments[0].dataSubject.nameTh}}</td>
        <td>{{item.dataComments[0].comment}}</td>
        <td>{{item.dataComments[0].date | date:'short'}}</td>
        <td>{{item.dataComments[0].scrore}}</td>
      </tr>
    </tbody>
  </table>
</div>