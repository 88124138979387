<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto" (click)="onClickNoni()">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"><i
          class="icon feather icon-bell"></i>
        <span class="badge bg-danger" *ngIf="dataNoti.length > 0">
          <span class="sr-only"></span>
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right
        notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">แจ้งเตือน</h6>
          <!-- <div class="float-right">
            <a href="javascript:" class="m-r-10">ทำเครื่องหมายว่าอ่านแล้ว</a>
            <a href="javascript:">ลบทั้งหมด</a>
          </div> -->
        </div>
        <ul class="noti-body" *ngIf="dataNoti.length > 0">
          <!-- <li class="n-title">
            <p class="m-b-0">ใหม่</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>จอร์น ชาวไร่</strong><span class="n-time text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>30 นาที</span></p>
                <p>เพิ่มข้อมูลหลักสูตร</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">ก่อนหน้านี้</p>
          </li> -->
          <li class="notification" *ngFor="let item of dataNoti" (click)="onClickOpenPage()">
            <div class="media">
              <img class="img-radius" src="{{baseURL}}{{item.img}}" alt="student
                image" height="40" style="object-fit: cover;"
                onerror="this.src='assets/images/no-img.png'">
              <div class="media-body">
                <p><strong>{{item.name}}</strong><span class="n-time
                    text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>{{item.date |
                    date:'short'}}</span></p>
                <p>{{item.content}}</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- <div class="noti-footer">
          <a href="javascript:">ดูทั้งหมด</a>
        </div> -->
        <div class="noti-footer"*ngIf="dataNoti.length <= 0">
          <a href="javascript:">ไม่มีข้อมูล</a>
        </div>
      </div>
    </div>
  </li>
  <!-- <li (click)="this.visibleUserList = !this.visibleUserList;">
    <div class="dropdown">
      <a href="javascript:" class="displayChatbox dropdown-toggle">
        <i class="icon feather icon-mail"></i><span class="badge bg-success"><span class="sr-only"></span></span>
      </a>
    </div>
  </li> -->
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="20">
       <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="20">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu>
        <ul class="pro-body">
          <li *ngFor="let item of listLang"><a href="javascript:void(0);" class="dropdown-item lang-item-list"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{'active': langStoreValue === item.lang}">
            <img src="{{item.flag}}" class="flag-img" height="20"><span class="align-middle" style="padding-left: 10px;">{{item.text}}</span>
          </a></li>
          <!-- <li ><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> ข้อความ</a></li> -->
        </ul>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu>
        <div class="pro-head" *ngIf="localRole != null">
          <span>{{localRole.username}}</span><br>
          <a (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li *ngIf="localRole.role != 'Teacher' && localRole.role !=
            'Executive'"><a [routerLink]="['/admin/profile']"
              class="dropdown-item"><i class="feather icon-user"></i> โปรไฟล์</a></li>
          <li *ngIf="localRole.role == 'Teacher'"><a
              [routerLink]="['/teacher/profile']" class="dropdown-item"><i
                class="feather icon-user"></i> โปรไฟล์</a></li>
          <li *ngIf="localRole.role == 'Executive'"><a
              [routerLink]="['/executive/profile']" class="dropdown-item"><i
                class="feather icon-user"></i> โปรไฟล์</a></li>
          <!-- <li ><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> ข้อความ</a></li> -->
        </ul>
        <div class="pro-head" *ngIf="localRole != null" style="margin-bottom: -25px;">
          <p style="font-size: 10px;margin-top: -9px;text-align: center;">Designed By A CHAT SOCIAL CO., LTD.</p>
        </div>
      </div>
    </div>
  </li>
</ul>

<section class="header-user-list" *ngIf="visibleUserList &&
  !this.gradientConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList= !this.visibleUserList;
    this.chatMessage= false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage &&
  !this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage= !this.chatMessage"
    [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList &&
  this.gradientConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList= !this.visibleUserList;
    this.chatMessage= false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage &&
  this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage= !this.chatMessage"
    [friendId]="friendId"></app-chat-msg>
</section>

<ng-snotify [ngClass]="style"></ng-snotify>