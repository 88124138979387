import { Component, OnInit } from '@angular/core';
import { ReportAllService } from 'src/app/core/services/report-all/report-all.service';

@Component({
  selector: 'app-report-pre-study-post-study-test-results',
  templateUrl: './report-pre-study-post-study-test-results.component.html',
  styleUrls: ['./report-pre-study-post-study-test-results.component.css']
})
export class ReportPreStudyPostStudyTestResultsComponent implements OnInit {

  constructor(
    private adminService: ReportAllService,
  ) { }
  
  ngOnInit() {
    var local = localStorage.getItem('currentUserLogin');
  }

  getFindReportExamSubject(subjectId:string){
    this.adminService.GetFindReportExamSubject_(subjectId).then(res => {
      // console.log(res);
      
    })
  }

}
