<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill" *ngIf="localRole == 'SuperAdmin' || localRole == 'Admin' || localRole ==
  'Executive'">
  <div class="row">
    <div class="col-sm-7">
      <h5>
        {{'EXECUTIVE.SEARCH-BY-NAME-LIST' | translate}}
      </h5>
    </div>
    <div class="col-sm-5">
      <ng-select [ngClass]="'ng-select'" [options]="dataTeacherAll"
        *ngIf="selectedTeacher != null && selectedTeacher != '' && selectedTeacher != undefined"
        [(ngModel)]="selectedTeacher" (ngModelChange)="getDataSubjectTeacher($event)">
        <ng-template let-option="dataStudentAll">
          <div class="famfamfam-flags {{dataTeacherAll?.value.toLowerCase()}}"></div>
          {{dataTeacherAll.label}}
        </ng-template>
      </ng-select>
      <p *ngIf="selectedTeacher == null || selectedTeacher == '' || selectedTeacher == undefined"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
    </div>
  </div>
</app-card>

<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="{{'EXECUTIVE.DOWNLOAD-THE-HIGHTEST-REVENUE' | translate}}" 
    style="margin-bottom: 10px;margin-right: 5px;float: right;" (click)="exportexcel('Report-income-teacher')">
      <i class="feather icon-file-text"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
    </button>
  </div>
  <!-- Income All Start-->
  <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
    <app-card [hidHeader]="true" cardClass="bg-c-blue order-card">
      <h6 class="text-white">{{'EXECUTIVE.REVENUE-REPORT-OF-ALL-COURSES' | translate}}</h6>
      <h2 class="text-right text-white" *ngIf="totalSubject != null"><i class="feather icon-activity
          float-left"></i><span>{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}}</span></h2>
      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.all.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
    </app-card>
    <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
      <h6 class="text-white">{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth2}}</h6>
      <h2 class="text-right text-white" *ngIf="totalSubject != null"><i class="feather icon-activity
          float-left"></i><span>{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}}</span></h2>
      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.lastMonth.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
    </app-card>
    <app-card [hidHeader]="true" cardClass="bg-c-red order-card">
      <h6 class="text-white">{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth1}}</h6>
      <h2 class="text-right text-white"  *ngIf="totalSubject != null"><i class="feather icon-activity
          float-left"></i><span>{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}}</span></h2>
      <p class="m-b-0">{{'EXECUTIVE.ALL-COURSES' | translate}}
        <span class="float-right" *ngIf="totalSubject != null">{{totalSubject.thisMonth.length}}
          {{'EXECUTIVE.COURSES' | translate}}</span>
        <span class="float-right" *ngIf="totalSubject == null">0 {{'EXECUTIVE.COURSES' | translate}}</span>
      </p>
    </app-card>
  </div>
  <!-- Income All End-->

  <!-- Top Course Start-->
  <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
          <div style="padding-top: 20px;padding-bottom: 10px;padding-left:
            10px;">
            <p style="font-size: 18px;"><b>{{'EXECUTIVE.TOP-10-OF-THE-HIGHTEST-REVENUE' | translate}}</b><i
                class="fas fa-level-up-alt text-c-green m-l-10"></i></p>
          </div>
          <perfect-scrollbar style="background-color: white; border-radius: 5px;
            height: 439px; top: 0px;" *ngIf="topBest != null && topBest !=
            undefined">
            <div class="review-block" *ngIf="!dataLoading2">
              <div class="row" *ngFor="let TenBest of topBest.topBest;index as
                i">
                <div class="col-sm-auto p-r-0">
                  <p style="font-size: 25px;"><b>Top {{i+1}}</b></p>
                </div>
                <div class="col">
                  <h6 class="m-b-0">{{TenBest.nameTh}}</h6>
                  <p class="m-b-0">{{TenBest.nameEn}}</p>
                  <div class="row align-items-center">
                    <div class="col">
                      <td>
                        <span><b style="color: green;">฿</b> {{'EXECUTIVE.REVENUE' | translate}}</span>
                      </td>
                    </div>
                    <div class="col">
                      <h6 class="m-b-0">{{TenBest.price | number}} {{'EXECUTIVE.BAHT' | translate}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading2">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
          <p *ngIf="!dataLoading2 && topBest == null"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
        </app-card>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
          <div style="padding-top: 20px;padding-bottom: 10px;padding-left:
            10px;">
            <p style="font-size: 18px;"><b>{{'EXECUTIVE.TOP-10-OF-THE-LOWEST-REVENUE' | translate}}</b><i
                class="fas fa-level-down-alt text-c-red m-l-10"></i></p>
          </div>
          <perfect-scrollbar style="background-color: white; border-radius: 5px;
            height: 439px; top: 0px;" *ngIf="topWorst != null && topWorst !=
            undefined">
            <div class="review-block" *ngIf="!dataLoading2">
              <div class="row" *ngFor="let TenWorst of topWorst.topWorst;index
                as i">
                <div class="col-sm-auto p-r-0">
                  <p style="font-size: 25px;"><b>Top {{i+1}}</b></p>
                </div>
                <div class="col">
                  <h6 class="m-b-0">{{TenWorst.nameTh}}</h6>
                  <p class="m-b-0">{{TenWorst.nameEn}}</p>
                  <div class="row align-items-center">
                    <div class="col">
                      <td>
                        <span><b style="color: red;">฿</b> {{'EXECUTIVE.REVENUE' | translate}}</span>
                      </td>
                    </div>
                    <div class="col">
                      <h6 class="m-b-0">{{TenWorst.price | number}} {{'EXECUTIVE.BAHT' | translate}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading2">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
          <p *ngIf="!dataLoading2 && topWorst == null"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
        </app-card>
      </div>
    </div>
  </div>
  <!-- Top Course End-->

  <!--Student List Start-->
  <div class="col-12">
    <app-card cardTitle="{{'EXECUTIVE.LEARNER-NAME-LIST' | translate}}" cardClass="card-datatable" [options]="false">
      <div class="table-responsive" *ngIf="dataStudent != null && dataStudent != '' && dataStudent != undefined">
        <table datatable class="table table-striped table-bordered nowrap
          table-hover" *ngIf="!dataLoading1">
          <thead>
            <tr>
              <th>{{'EXECUTIVE.NAME' | translate}}</th>
              <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
              <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
              <th>{{'EXECUTIVE.ATTENDANCE-STATUS' | translate}}</th>
              <th>{{'EXECUTIVE.COURSE-PRICE' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let std of dataStudent">
              <td>{{std.dataStudent.fullNameTh}}</td>
              <td>{{std.dataStudent.email}}</td>
              <td>{{std.dataSubject.nameTh}}</td>
              <td>
                <a *ngIf="std.typePayment == 'bank-transfer'" class="badge
                  badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.LOST-MONEY' | translate}}</a>
                <a *ngIf="std.typePayment == 'free'" class="badge
                  badge-light-success badge-pill f-12 mr-2">{{'EXECUTIVE.FREE' | translate}}</a>
                <a *ngIf="std.typePayment == 'ticket'" class="badge
                  badge-light-primary badge-pill f-12 mr-2">{{'EXECUTIVE.USE-TICKET' | translate}}</a>
                <a *ngIf="std.statusPay == 'reject_amount'" class="badge
                  badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.CANCELED' | translate}}</a>
                <a *ngIf="std.statusPay == 'pending'" class="badge
                  badge-light-warning badge-pill f-12 mr-2">{{'EXECUTIVE.WAITING-FOR-VERIFY' | translate}}</a>
              </td>
              <td>{{std.dataSubject.price | number}}</td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
        <div style="text-align: center;margin: 20px;">
          <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div>
      </div>
      <p *ngIf="dataStudent == null || dataStudent == '' || dataStudent == undefined"
        style="text-align: center;padding-top: 10px;"><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
    </app-card>
  </div>
  <!--Student List End-->
</div>

<table witdh="100%" border="1" class="table" #table11 id="excel-table-Report-income-teacher" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REVENUE-REPORT-OF-TEACHER-COURSES' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngFor="let item of dataTeacherAll">
    <tr *ngIf="item.value == selectedTeacher">
      <td>{{'EXECUTIVE.TEACHER-NAME' | translate}} {{item.label}} ({{'EXECUTIVE.TEACHER-ID' | translate}}{{selectedTeacher}})</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REVENUE-REPORT-OF-ALL-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth2}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-COURSES-OF' | translate}} {{IncomeMonth1}}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td *ngIf="totalSubject != null">{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.lastMonth.length}} {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject == null">{{sumtotal | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject != null">{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.lastMonth.length}} {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject == null">{{sumlast | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject != null">{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} {{totalSubject.thisMonth.length}} {{'TEACHER.COURSES' | translate}})</td>
      <td *ngIf="totalSubject == null">{{sumthis | number}} {{'EXECUTIVE.BAHT' | translate}} ({{'ADMIN.QUESTIONNAIRE.TOTAL' | translate}} 0 {{'TEACHER.COURSES' | translate}})</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-HIGHTEST-REVENUE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'ADMIN.COURSE.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'ADMIN.COURSE.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-INCOME' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topBest != null">
    <tr *ngFor="let TenBest of topBest.topBest;index as i">
      <td>Top {{i+1}}</td>
      <td>{{TenBest.nameTh}}</td>
      <td>{{TenBest.nameEn}}</td>
      <td>฿ {{TenBest.price | number}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-LOWEST-REVENUE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'ADMIN.COURSE.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'ADMIN.COURSE.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.TOTAL-REVENUE-INCOME' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topBest != null">
    <tr *ngFor="let TenBest of topBest.topWorst;index as i">
      <td>Top {{i+1}}</td>
      <td>{{TenBest.nameTh}}</td>
      <td>{{TenBest.nameEn}}</td>
      <td>฿ {{TenBest.price | number}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.LEARNER-NAME-LIST' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.NAME' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
      <th>{{'EXECUTIVE.ATTENDANCE-STATUS' | translate}}</th>
      <th>{{'EXECUTIVE.COURSE-PRICE' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="dataStudent != null">
    <tr *ngFor="let std of dataStudent">
      <td>{{std.dataStudent.fullNameTh}}</td>
      <td>{{std.dataStudent.email}}</td>
      <td>{{std.dataSubject.nameTh}}</td>
      <td>
        <a *ngIf="std.typePayment == 'bank-transfer'" class="badge
          badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.LOST-MONEY' | translate}}</a>
        <a *ngIf="std.typePayment == 'free'" class="badge
          badge-light-success badge-pill f-12 mr-2">{{'EXECUTIVE.FREE' | translate}}</a>
        <a *ngIf="std.typePayment == 'ticket'" class="badge
          badge-light-primary badge-pill f-12 mr-2">{{'EXECUTIVE.USE-TICKET' | translate}}</a>
        <a *ngIf="std.statusPay == 'reject_amount'" class="badge
          badge-light-danger badge-pill f-12 mr-2">{{'EXECUTIVE.CANCELED' | translate}}</a>
        <a *ngIf="std.statusPay == 'pending'" class="badge
          badge-light-warning badge-pill f-12 mr-2">{{'EXECUTIVE.WAITING-FOR-VERIFY' | translate}}</a>
      </td>
      <td>{{std.dataSubject.price | number}}</td>
    </tr>
  </tbody>
</table>
