import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { GradientConfig } from '../../../../../../app-config';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { Socket } from 'ngx-socket-io';
import { AdminComfirmationComponent } from 'src/app/modules/admin/components/admin-comfirmation/admin-comfirmation.component';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() item: NavigationItem;
  public gradientConfig: any;
  public themeLayout: string;
  resMemberStudent: boolean = false;
  constructor(private location: Location, private router: Router, private adminService: AdminService, private socket_: Socket,) {
    this.gradientConfig = GradientConfig.config;
    this.themeLayout = this.gradientConfig['layout'];
  }

  ngOnInit() {
    this.loadSocket();
    this.GetStudentRegisterList();
  }

  closeOtherMenu(event) {
    if (this.gradientConfig['layout'] === 'vertical') {
      const ele = event.target;
      if (ele !== null && ele !== undefined) {
        const parent = ele.parentElement;
        const up_parent = parent.parentElement.parentElement;
        const last_parent = up_parent.parentElement;
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        if (parent.classList.contains('pcoded-hasmenu')) {
          parent.classList.add('pcoded-trigger');
          parent.classList.add('active');
        } else if (up_parent.classList.contains('pcoded-hasmenu')) {
          up_parent.classList.add('pcoded-trigger');
          up_parent.classList.add('active');
        } else if (last_parent.classList.contains('pcoded-hasmenu')) {
          last_parent.classList.add('pcoded-trigger');
          last_parent.classList.add('active');
        }
      }
      if ((document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
      }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        let current_url = this.location.path();
        if (this.location['_baseHref']) {
          current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const up_parent = parent.parentElement.parentElement;
          const last_parent = up_parent.parentElement;
          if (parent.classList.contains('pcoded-hasmenu')) {
            parent.classList.add('active');
          } else if (up_parent.classList.contains('pcoded-hasmenu')) {
            up_parent.classList.add('active');
          } else if (last_parent.classList.contains('pcoded-hasmenu')) {
            last_parent.classList.add('active');
          }
        }
      }, 500);
    }
  }

  loadSocket() {
    var local = localStorage.getItem('currentUserLogin');
    var dataLocal = JSON.parse(local);
    this.socket_.on('connect', (data: any) => {
      console.log('socket connected');
    });
    this.socket_.on('actionElearningChannel', (data: any) => {
      if (
        dataLocal.role == 'Admin' && data.action == 'register' ||
        (dataLocal.role == 'SuperAdmin' && data.action == 'register')
      ) {
        this.resMemberStudent = true;
      }
    });
  }

  GetStudentRegisterList() {
    this.adminService.GetStudentRegisterList().then(res => {
      if (res.status) {
        this.resMemberStudent = true;
      } else {
        this.resMemberStudent = false;
      }
    })
  }
  logout() {
    Swal.fire({
      // title: "กดปุ่มยืนยันเพื่อออกจากระบบ",
      text: 'กดปุ่มยืนยันเพื่อออกจากระบบ',
      imageUrl: 'assets/images/logout.gif',
      imageHeight: 150,
      confirmButtonColor: "#4099FF",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonColor: "#bdc3c7",
      cancelButtonText: "ยกเลิก",
      allowOutsideClick: false,

    }).then((result) => {
      // console.log(result);
      if (result.value) {
        localStorage.removeItem("currentUserLogin");
        localStorage.removeItem("select_portal");
        this.router.navigate([`/auth/signin`], { replaceUrl: true }).then(() => { window.location.reload(); });
      } else {
        console.log("error : ");
      }
    });
  }

}
